import '../../MapMenu.scss';
import { lowercase } from '@utils';
import { useEffect, useState } from 'react';
import { Button, Card, Form, InputGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Icon, Text } from '../../..';

export function MapEditItem({
  selected,
  clickedItem,
  mapName,
  mapLayers,
  setShowMore,
  setUpdateItem,
  setDeleteItem,
}) {
  const [movingItem, setMovingItem] = useState<boolean>(false);
  const [invalid, setInvalid] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    clickedItem.editing._enabled ? setMovingItem(true) : setMovingItem(false);
  }, [clickedItem]);

  return (
    <Card>
      <div className="object-wrapper-expand">
        <div>
          {clickedItem.feature.properties.EntityType !== 'Asset' && (
            <div className="status-row d-flex flex-row align-items-center justify-content-between back-button">
              {(selected === 'asset' || selected === 'stationary') && (
                <Button
                  title="Go to Asset"
                  className="btn-light d-flex justify-content-between align-items-center nav-text-icon"
                  variant="light"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    history.push('/assets/' + clickedItem.feature.properties.Id);
                    e.stopPropagation();
                  }}
                >
                  <i className={`sonarIcon sonarIcon-open-in fs-md`} />
                </Button>
              )}
              {selected === 'place' && (
                <Button
                  title="Go to Place"
                  className="btn-light d-flex justify-content-between align-items-center nav-text-icon"
                  variant="light"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    history.push('/places/' + clickedItem.feature.properties.Id);
                    e.stopPropagation();
                  }}
                >
                  <i className={`sonarIcon sonarIcon-open-in fs-md`} />
                </Button>
              )}
              {!movingItem ? (
                <Icon
                  name={'pin-edit'}
                  title="Edit Placement"
                  className="align-sub primary-icon"
                  variant="light"
                  disabled={
                    !mapLayers.includes(lowercase(clickedItem.feature.properties.EntityType) + 's')
                  }
                  size="md"
                  onClick={() => {
                    setMovingItem(true);
                    clickedItem?.editing?.enable();
                  }}
                />
              ) : (
                <Icon
                  name={'check'}
                  title="Confirm"
                  className="align-sub primary-icon"
                  variant="light"
                  disabled={invalid}
                  size="md"
                  onClick={() => {
                    setMovingItem(false);
                    if (
                      mapLayers.includes(lowercase(clickedItem.feature.properties.EntityType) + 's')
                    ) {
                      clickedItem.editing.disable();
                    }
                    setUpdateItem(true);
                  }}
                />
              )}
              <Icon
                name={'trash'}
                title="Delete Place"
                className="align-sub danger-icon"
                variant="light"
                disabled={false}
                size="md"
                onClick={() => {
                  setDeleteItem(true);
                  clickedItem.remove();
                  setShowMore(null);
                }}
              />
              <Button
                title={selected ? 'Back' : 'Close'}
                className="btn-light d-flex flex-row align-items-center justify-content-between nav-text-icon"
                variant="light"
                onClick={() => {
                  if (
                    mapLayers.includes(lowercase(clickedItem.feature.properties.EntityType) + 's')
                  ) {
                    clickedItem.editing.disable();
                  }
                  setShowMore(null);
                }}
              >
                {selected ? (
                  <i className="sonarIcon sonarIcon-left-arrow fs-md" />
                ) : (
                  <i className="sonarIcon sonarIcon-add sonarIcon-rotate-45 fs-md nav-text-icon" />
                )}
              </Button>
            </div>
          )}
        </div>
        {clickedItem.feature && (
          <div className="edit-feature">
            {clickedItem.feature.properties.Name && (
              <>
                {clickedItem.feature.properties.EntityType === 'Place' ? (
                  <InputGroup className="edit-field">
                    <Text className="fw-bold fs-md d-flex align-items-center">Place: &nbsp;</Text>
                    <Form.Control
                      defaultValue={clickedItem.feature.properties.Name}
                      aria-label="Name"
                      isInvalid={invalid}
                      onChange={(e) => {
                        setMovingItem(true);
                        clickedItem?.editing?.enable();
                        if (e.target.value) {
                          setInvalid(false);
                          clickedItem.feature.properties.Name = e.target.value;
                        } else {
                          setInvalid(true);
                        }
                      }}
                    />
                  </InputGroup>
                ) : (
                  <div className="status-row d-flex align-items-center">
                    <Text className="fw-bold fs-md">Name: &nbsp;</Text>
                    <Text className="fs-md">{clickedItem.feature.properties.Name}</Text>
                  </div>
                )}
              </>
            )}
            {clickedItem.feature.properties.DeviceId && (
              <div className="status-row d-flex align-items-center">
                <Text className="fw-bold fs-md">Device ID: &nbsp;</Text>
                <Text className="fs-md">{clickedItem.feature.properties.DeviceId}</Text>
              </div>
            )}
            {clickedItem.feature.properties.LastCheckIn && (
              <div className="status-row d-flex align-items-center">
                <Text className="fw-bold fs-md">Last Check-In: &nbsp;</Text>
                <Text className="fs-md">{clickedItem.feature.properties.LastCheckIn}</Text>
              </div>
            )}
            {clickedItem.feature.properties.OnlineStatus && (
              <div className="status-row d-flex align-items-center">
                <Text className="fw-bold fs-md">Status: &nbsp;</Text>
                <Text className="fs-md">{clickedItem.feature.properties.OnlineStatus}</Text>
              </div>
            )}
            {clickedItem.feature.properties.Floor && (
              <>
                {clickedItem.feature.properties.EntityType === 'Gateway' ? (
                  <InputGroup className="edit-field">
                    <Text className="fw-bold fs-md d-flex align-items-center">Floor: &nbsp;</Text>
                    <Form.Control
                      defaultValue={clickedItem.feature.properties.Floor}
                      type="number"
                      aria-label="Floor"
                      isInvalid={invalid}
                      min={1}
                      onChange={(e) => {
                        setMovingItem(true);
                        clickedItem?.editing?.enable();
                        if (parseInt(e.target.value) >= 1) {
                          setInvalid(false);
                          clickedItem.feature.properties.Floor = e.target.value;
                        } else {
                          setInvalid(true);
                        }
                      }}
                    />
                  </InputGroup>
                ) : (
                  <div className="status-row d-flex align-items-center">
                    <Text className="fw-bold fs-md">Floor: &nbsp;</Text>
                    <Text className="fs-md">{clickedItem.feature.properties.Floor}</Text>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </Card>
  );
}
