import 'react-bootstrap-typeahead/css/Typeahead.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { lazy, Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import * as loadingAnim from './animations/loading.json';
import { ErrorBoundary, Layout, Loading } from './components';
import { Text } from './components';
import StoreWrapper from './components/EquipmentInfo/StoreWrapper';
import PrivateRoute from './components/utils/privateRoute';
import { AssetListContainer } from './containers/assets/AssetListContainer';
import { PlaceListContainer } from './containers/places/PlaceListContainer';
import { TriggerListContainer } from './containers/triggers/TriggerListContainer';
import BreadcrumbsContainer from './modules/location/containers/breadcrumbsContainer';
import MapEditContainer from './modules/location/containers/mapEditContainer';
import { history, persistor, store } from './redux';
import { AdminPortalApps, AdminUsers } from './screens/AdminPortal';
import Articles from './screens/Articles';
import SingleArticle from './screens/Articles/Single';
import { AdvancedAsset, AssetContainer, BeaconIdRedirectToAsset } from './screens/assets';
import {
  BeaconTypesScreen,
  BeaconUpload,
  BulkAssetUpload,
  GatewayUpload,
} from './screens/bulkUpload';
import EnvScreen from './screens/env/EnvScreen';
import Equipment from './screens/Equipment';
import SingleEquipment from './screens/Equipment/SingleEquipment';
import { MapDetail } from './screens/maps';
import { MapDetailV2 } from './screens/mapsv2/';
import { CreateMap, MapList, MapListBeta } from './screens/mapsv2/';
import { PlaceScreen } from './screens/places';
import Reports from './screens/reports';
import { userMgmt } from './screens/userMgmt';
import { ChangePassword, NewUserChangePassword } from './screens/userProfile';
import { matomo } from './scripts/matomotracking.js';
import { isLegacyAuthEnabled } from './utils';

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: 0,
    },
  },
});

const DeviceMgmtListScreen = lazy(() => import('./screens/deviceMgmt'));
const LoginContainer = lazy(() => import('./screens/login'));
const Dashboard = lazy(() => import('./screens/dashboard'));
const Analytics = lazy(() => import('./screens/Analytics'));

function App() {
  useEffect(() => {
    const unlisten = history.listen((location) => {
      matomo();
    });
    return () => {
      unlisten();
    };
  }, []);

  return (
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <PersistGate
              loading={<Loading animationData={loadingAnim.default} />}
              persistor={persistor}
            >
              <Router history={history}>
                <StoreWrapper>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                      <Route path="/login" component={LoginContainer} />

                      <Route
                        path="/authentication/password-reset"
                        component={NewUserChangePassword}
                        disabled={!isLegacyAuthEnabled()}
                      />

                      <Route
                        path={`/`}
                        render={() => {
                          return (
                            <Layout>
                              <Switch>
                                <PrivateRoute exact path="/">
                                  <Redirect to={'/dashboard'} />
                                </PrivateRoute>
                                <PrivateRoute path="/debug-env" component={EnvScreen} />
                                <PrivateRoute path="/analytics/:activeTab?" component={Analytics} />
                                <PrivateRoute
                                  path="/change-password"
                                  component={ChangePassword}
                                  disabled={!isLegacyAuthEnabled()}
                                />
                                <PrivateRoute path="/beacon-upload" component={BeaconUpload} />
                                <PrivateRoute path="/gateway-upload" component={GatewayUpload} />
                                <PrivateRoute path="/assets-upload" component={BulkAssetUpload} />
                                <PrivateRoute path="/beacon-types" component={BeaconTypesScreen} />
                                <PrivateRoute
                                  path={`/admin/user-management`}
                                  component={userMgmt}
                                  disabled={!isLegacyAuthEnabled()}
                                />
                                <PrivateRoute
                                  path={`/admin/app`}
                                  component={AdminPortalApps}
                                  disabled={!isLegacyAuthEnabled()}
                                />
                                <PrivateRoute
                                  path={`/admin/users`}
                                  component={AdminUsers}
                                  disabled={!isLegacyAuthEnabled()}
                                />
                                <PrivateRoute
                                  path="/maps/create"
                                  checkReadOnly
                                  component={CreateMap}
                                />
                                <PrivateRoute
                                  path={`/maps/:mapId/edit`}
                                  component={MapEditContainer}
                                  checkReadOnly
                                />
                                <PrivateRoute
                                  path={`/mapbeta/:mapId/edit`}
                                  component={MapDetailV2}
                                  checkReadOnly
                                />
                                <PrivateRoute path="/maps/:mapId" component={MapDetail} />
                                <PrivateRoute path="/maps" component={MapList} />
                                <PrivateRoute path="/mapbeta/:mapId" component={MapDetailV2} />
                                <PrivateRoute path="/mapbeta" component={MapListBeta} />
                                <PrivateRoute path="/help/:id" component={SingleArticle} />
                                <PrivateRoute path="/help" component={Articles} />
                                <PrivateRoute path="/equipment/:id" component={SingleEquipment} />
                                <PrivateRoute path="/equipment" component={Equipment} />
                                <PrivateRoute
                                  path="/admin/device-management/:type"
                                  component={DeviceMgmtListScreen}
                                />
                                <PrivateRoute
                                  path={`/assets/:thingId/maps`}
                                  component={BreadcrumbsContainer}
                                />
                                <PrivateRoute
                                  path="/assets/:assetId/advanced"
                                  component={AdvancedAsset}
                                />
                                <PrivateRoute path="/assets/:assetId" component={AssetContainer} />
                                <PrivateRoute path="/assets" component={AssetListContainer} />
                                <PrivateRoute
                                  path="/beacons/:deviceId/asset"
                                  component={BeaconIdRedirectToAsset}
                                />
                                <PrivateRoute component={PlaceScreen} path="/places/:placeId" />
                                <PrivateRoute component={PlaceListContainer} path="/places" />
                                <PrivateRoute
                                  path={`/triggers/resolve/:appId/:instanceId/:resolvedById`}
                                  component={TriggerListContainer}
                                />
                                <PrivateRoute
                                  path={`/triggers/:id?`}
                                  component={TriggerListContainer}
                                />
                                <PrivateRoute path="/dashboard" component={Dashboard} />
                                <PrivateRoute path="/dashboard-edit" component={Dashboard} />
                                <PrivateRoute path="/reports/:reportType" component={Reports} />
                                <PrivateRoute exact path="/" component={Dashboard} />
                                <Route
                                  render={() => (
                                    <div className="text-center">
                                      <Text as="h1" className="fs-3x fw-bold text-center pt-5">
                                        <i class="fas fa-exclamation-triangle h1 page-not-found"></i>
                                      </Text>
                                      <h6>This page could not be found.</h6>
                                      <div
                                        className="btn btn-transparent pt-5"
                                        onClick={() => history.push('/')}
                                      >
                                        <i class="fas fa-arrow-circle-left text-primary pe-1"></i>
                                        <u className="text-primary">Go to Dashboard</u>
                                      </div>
                                    </div>
                                  )}
                                />
                              </Switch>
                            </Layout>
                          );
                        }}
                      />
                    </Switch>
                  </Suspense>
                </StoreWrapper>
              </Router>
            </PersistGate>
          </Provider>
        </QueryClientProvider>
      </LocalizationProvider>
    </ErrorBoundary>
  );
}

export default App;
