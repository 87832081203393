import '../../scss/variables.scss';
import './Map.scss';
import { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, Col, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import {
  BeaconReadingsCellRenderer,
  Icon,
  LinkCellRenderer,
  OnlineStatusCellRenderer,
  PlacesCellRenderer,
  Text,
  toast,
} from '../../components';
import { PagedQueryParams, ServerSideGrid } from '../../components/grids/AgGrid/ServerSideGrid';
import { IGNORED_ABILITIES } from '../../constants';
import LocationMap from '../../modules/location/components/locationMap';
import { history } from '../../redux';
import { Api } from '../../services';
import { assetColumnDefs } from './columnDefs';

// #region API FUNCTIONS
async function fetchMapList() {
  const { data } = await Api.get('/maps?top=20&skip=0&filter=&applicationId=1');
  return data;
}

async function fetchMapLayout({ mapId }) {
  const { data } = await Api.get(`/maps/${mapId}/layout`);
  return data;
}

async function fetchMapAssets({ payload }) {
  const { data } = await Api.post(`/v2/assets/filter/`, payload);
  return data;
}

async function fetchMapGateways({ mapId }) {
  const { data } = await Api.get(`/maps/${mapId}/gateways?top=1000000`);
  return data;
}

async function fetchMapStationaryBeacons({ mapId }) {
  const { data } = await Api.get(`/maps/${mapId}/stationaryBeacons?top=1000000`);
  return data;
}

async function favoriteMap({ mapId }) {
  return Api.post(`/maps/${mapId}/favorites`);
}

async function unfavoriteMap({ mapId }) {
  return Api.delete(`/maps/${mapId}/favorites`);
}
// #endregion API FUNCTIONS

function MapDetail({ match, location }) {
  const { mapId } = match.params;
  const mapName = location.state?.mapName || '';
  const [isLoading, setIsLoading] = useState(false);
  const [mapLayers, setMapLayers] = useState([]);
  const [layers, setLayers] = useState([]);
  const [reading, setReading] = useState('Temperature');
  const [mapAssetsRes, setMapAssetsRes] = useState([]);
  const [error, setError] = useState(null);
  const assetFilters = JSON.parse(localStorage.getItem('assetFilters')).concat(
    JSON.parse(localStorage.getItem('tempFilters')) || [],
  );
  const defaultFilter = [{ field: 'mapId', type: 'equal', filter: mapId }];
  const mapLayoutRes = useQuery([`map-${mapId}-layout`], () => fetchMapLayout({ mapId }));
  const mapGatewaysRes = useQuery([`map-${mapId}-gateways`], () => fetchMapGateways({ mapId }), {
    enabled: !error?.name === 'MapDeletedError',
  });

  const mapStatBeaconsRes = useQuery(
    [`map-${mapId}-stationaryBeacons`],
    () => fetchMapStationaryBeacons({ mapId }),
    { enabled: !error?.name === 'MapDeletedError' },
  );

  // Fetch map layout and check if the map exists
  const checkMapExists = async () => {
    try {
      const mapList = await fetchMapList();
      const mapExists = mapList.items.some((map) => map.id === Number(mapId));

      if (!mapExists) {
        // If the map doesn't exist, set an error state
        setError({
          name: 'MapDeletedError',
          message: `has been deleted.`,
        });
      } else {
        const mapLayout = await fetchMapLayout({ mapId });
      }
    } catch (err) {
      console.error('Error fetching map list:', err);
      setError({ name: 'GenericError', message: 'Request failed. Please try again.' });
    } finally {
      setIsLoading(false);
    }
  };

  // UseEffect to check if the map exists when the component mounts
  useEffect(() => {
    checkMapExists();
  }, [mapId]);

  const getData = (payload) => {
    if (!mapLayoutRes.isLoading && !error) {
      setIsLoading(true);
      fetchMapAssets({ payload }).then(function (result) {
        setMapAssetsRes(result);
        setIsLoading(false);
      });
    }
  };

  const favoriteMapMutation = useMutation(favoriteMap);
  const unfavoriteMapMutation = useMutation(unfavoriteMap);

  const callFavoriteMap = async () => {
    try {
      await favoriteMapMutation.mutateAsync({ mapId });
      mapLayoutRes.refetch();
    } catch (error) {
      toast.error('Failed to favorite the map.');
    }
  };

  const callUnfavoriteMap = async () => {
    try {
      await unfavoriteMapMutation.mutateAsync({ mapId });
      mapLayoutRes.refetch();
    } catch (error) {
      toast.error('Failed to unfavorite the map.');
    }
  };

  const { data: map } = mapLayoutRes;

  const assetLocs = useMemo(
    () =>
      (mapAssetsRes?.items || [])
        .map((asset) => {
          const coordinates = asset.location?.coordinates || {}; // Safe check for coordinates
          const { x, y } = coordinates; // Safely destructure coordinates
          if (x == null || y == null) return null; // Return null if coordinates are invalid

          return {
            location: { x, y, type: 0 },
            isVisible: true,
            mapId: Number(mapId),
            name: asset.name,
            autoPopup: true,
            asset,
            fillColor: asset.status === 1 ? 'var(--c-primary)' : 'var(--danger)',
            fillOpacity: 0.3,
            color: asset.status === 1 ? 'var(--c-primary)' : 'var(--danger)',
          };
        })
        .filter(Boolean), // Filter out null values
    [mapAssetsRes, mapId],
  );

  const gatewayLocs = useMemo(
    () =>
      (mapGatewaysRes?.data?.items || []).map((gateway, index) => {
        return {
          gateway,
          isVisible: (mapLayers || []).includes('gateways'),
          location: {
            type: 0,
            x: gateway.location.x,
            y: gateway.location.y,
          },
          index,
          autoPopup: true,
        };
      }),
    [mapLayers, mapGatewaysRes],
  );

  const places = useMemo(
    () =>
      (mapLayoutRes?.data?.places || []).map((place, index) => {
        return {
          ...place,
          isVisible: (mapLayers || []).includes('places'),
          index,
        };
      }),
    [mapLayers, mapLayoutRes],
  );

  // map beacons to shape of leafletBeaconFactory.
  // TODO: will want to refactor leafletBeaconFactory and change this mapping
  const stationaryBeacons = useMemo(
    () =>
      mapStatBeaconsRes.data?.items.map((beacon) => {
        const { readings } = beacon;

        const filteredReadings = readings.filter(
          (reading) => !IGNORED_ABILITIES.includes(reading.ability),
        );

        const readingsObj = filteredReadings.reduce((accum, reading) => {
          accum[reading.ability] = reading;
          return accum;
        }, {});

        return {
          ...beacon,
          beacon: {
            ...beacon,
            ...readingsObj,
          },
          isVisible: (mapLayers || []).includes('beacons'),
          autoPopup: true,
        };
      }),
    [mapLayers, mapStatBeaconsRes],
  );

  const onAssetClicked = useCallback(
    ({ node }) => {
      const mapLayer = layers?.find((layer) => layer.data.asset?.id === node.data?.id);
      mapLayer
        ?.bindPopup(
          `<br/>
          <div class='d-flex flex-row align-items-center'>
            <strong class='col-10'>${mapLayer.data.asset.name}</strong> 
            <a
              class='btn-light d-flex justify-content-left align-items-center' href='#'
              onclick="CBCopy('${mapLayer.data.asset.name}')"
            > 
              <i class="col-1 far fa-lg fa-copy" ></i> 
            <a>
          </div>
          <hr/>
          <div class="row">
            <div class="col text-center" style="text-align:center;">
              <span class="btn btn-primary btn-sm" type="button" onclick={window.markers.locations?.[${mapLayer.data.asset?.id}]()}>Go to Asset</span>
            </div>
          </div>`,
          { autoPan: false },
        )
        .openPopup();
    },
    [layers],
  );

  if (mapLayoutRes.isLoading) return null;

  // Handle deleted map scenario
  if (error) {
    return (
      <div className="h-100 d-flex flex-column justify-content-around align-items-center">
        <Text as="h1" variant="danger" className="fs-2x">
          The Map <span className="map-name-primary">{mapName}</span> {error.message}
        </Text>
        <Button className="retry-btn" onClick={() => history.goBack()}>
          Return to Previous Page
        </Button>
      </div>
    );
  } else {
    return (
      <Card className="h-100 overflow-hidden map-detail-card-wrap">
        <Card.Header>
          <Row>
            <Col>
              <div className="d-flex flex-row justify-content-between">
                {map && (
                  <Text as="h1" className="fs-2x">
                    {map.name}
                  </Text>
                )}
                <Icon
                  name={map.favorite ? 'star-fill' : 'star-empty'}
                  variant="light"
                  size="lg"
                  className="align-sub"
                  disabled={false}
                  showPointer={true}
                  onClick={map.favorite ? callUnfavoriteMap : callFavoriteMap}
                />
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="h-90">
          <Row className="h-100">
            <Col xs={6} xl={6} className="h-100 overflow-x-auto">
              {map && mapAssetsRes && (
                <ServerSideGrid
                  id={map.name}
                  initQueryParams={new PagedQueryParams()}
                  assetFilters={assetFilters}
                  columnDefs={assetColumnDefs}
                  handleFetchData={(payload, isCSV) => {
                    getData(
                      payload,
                      defaultFilter.map((item) => payload.filters.push(item)),
                    );
                  }}
                  rowData={mapAssetsRes || []}
                  isLoading={isLoading}
                  disabledFilters={['all', 'map', 'place', 'device id', 'battery value']}
                  filterControl={true}
                  searchControl={true}
                  viewingControl={true}
                  pagerControl={true}
                  exportControl={false}
                  gridProps={{
                    frameworkComponents: {
                      OnlineStatusCellRenderer,
                      BeaconReadingsCellRenderer,
                      LinkCellRenderer,
                      PlacesCellRenderer,
                    },
                    onCellClicked: onAssetClicked,
                  }}
                />
              )}
            </Col>

            <Col xs={6} xl={6} className="map-detail-container">
              <Row>
                <Col className="d-flex flex-column align-items-center justify-content-around">
                  <ToggleButtonGroup
                    className="d-flex flex-row align-items-center justify-content-between"
                    type="checkbox"
                    value={mapLayers}
                    onChange={(val) => {
                      setMapLayers(val);
                    }}
                  >
                    <ToggleButton
                      className="toggle-button mb-3"
                      id={'gateways'}
                      variant={mapLayers.includes('gateways') ? 'primary' : 'secondary'}
                      value={'gateways'}
                      title="Toggle Gateways"
                    >
                      <i className="sonarIcon sonarIcon-gateway" />
                    </ToggleButton>

                    <ToggleButton
                      className="mb-3"
                      id={'places'}
                      variant={mapLayers.includes('places') ? 'primary' : 'secondary'}
                      value={'places'}
                      title="Toggle Places"
                    >
                      <i className="sonarIcon sonarIcon-place" />
                    </ToggleButton>

                    <ToggleButton
                      className="mb-3"
                      id={'beacons'}
                      variant={mapLayers.includes('beacons') ? 'primary' : 'secondary'}
                      value={'beacons'}
                      title="Toggle Beacons"
                    >
                      <i className="sonarIcon sonarIcon-stationary-beacon" />
                    </ToggleButton>
                  </ToggleButtonGroup>

                  {/* Button group to show readings */}
                  {mapLayers.includes('beacons') && (
                    <ToggleButtonGroup
                      className="d-flex flex-row align-items-center justify-content-between"
                      type="radio"
                      value={reading}
                      name="readings"
                      onChange={(val) => setReading(val)}
                    >
                      <ToggleButton
                        className="mb-3"
                        id={'Temperature'}
                        variant={reading === 'Temperature' ? 'primary' : 'secondary'}
                        value="Temperature"
                        title="Toggle Temperature"
                      >
                        <i className="sonarIcon sonarIcon-temperature" />
                      </ToggleButton>
                      <ToggleButton
                        className="mb-3"
                        id={'Humidity'}
                        variant={reading === 'Humidity' ? 'primary' : 'secondary'}
                        value="Humidity"
                        title="Toggle Humidity"
                      >
                        <i className="sonarIcon sonarIcon-humidity" />
                      </ToggleButton>
                      <ToggleButton
                        className="mb-3"
                        id={'Vibration'}
                        variant={reading === 'Vibration' ? 'primary' : 'secondary'}
                        value="Vibration"
                        title="Toggle Vibration"
                      >
                        <i className="sonarIcon sonarIcon-vibration" />
                      </ToggleButton>
                      <ToggleButton
                        className="mb-3"
                        id={'Battery'}
                        variant={reading === 'Battery' ? 'primary' : 'secondary'}
                        value="Battery"
                        title="Toggle Battery"
                      >
                        <i className="sonarIcon sonarIcon-battery" />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                </Col>
              </Row>
              <div className="location-map-container">
                <div className="location-map-container">
                  {!mapLayoutRes.isLoading && map ? (
                    <LocationMap
                      id="map-container"
                      map={map}
                      height="90%"
                      locations={assetLocs || []}
                      gateways={gatewayLocs || []}
                      zones={places || []}
                      beacons={stationaryBeacons || []}
                      reading={reading}
                      onSetLocationLayers={(e) => setLayers(e)}
                    />
                  ) : (
                    <div>Loading map...</div> // Or another loading indicator
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

export default MapDetail;
