import './FilterContainer.scss';
import '../scss/variables.scss';
import { useEffect, useRef, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { Filter } from '../components/inputs/Filter';
import { AssetFilter } from './../components/AssetFilter/AssetFilter';
import { TrendFilter } from './../components/TrendFilter/TrendFilter';

interface FilterIconProps {
  readonly isActive: boolean;
}

const StyledFilterIcon = styled.span<FilterIconProps>`
  pointer-events: none;
  circle {
    stroke: var(--nav-text);
    fill: var(--nav-text);
    will-change: transform;
    opacity: ${(props) => (props.isActive ? 0 : 'initial')};
  }
  rect {
    transform-origin: 50% 50%;
  }
  .fltr-line1 {
    transform: ${(props) => (props.isActive ? 'rotate(45deg) translateY(5.5px)' : 'initial')};
  }
  .fltr-line2 {
    transform: ${(props) => (props.isActive ? 'rotate(-45deg) translateY(-4.5px)' : 'initial')};
  }
  &:hover {
    .fltr-crcl1 {
      transform: translateX(6px);
    }
    .fltr-crcl2 {
      transform: translateX(-6px);
    }
  }
  svg {
    stroke: var(--nav-text);
    width: 1.4rem;
    height: 1.4rem;
    > * {
      transition: 0.15s cubic-bezier(0.35, 0.35, 0, 1);
    }
  }
`;

const AssetFilterIcon = ({ isActive }) => {
  return (
    <StyledFilterIcon isActive={isActive}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect className="fltr-line1" x="7" y="10" width="18" height="1" fill="black" />
        <rect className="fltr-line2" x="7" y="20" width="18" height="1" fill="black" />
        <circle className="fltr-crcl1" cx="13" cy="20.5" r="2.5" fill="white" stroke="black" />
        <circle className="fltr-crcl2" cx="19" cy="10.5" r="2.5" fill="white" stroke="black" />
      </svg>
    </StyledFilterIcon>
  );
};

const TrendFilterIcon = ({ isActive }) => {
  return (
    <StyledFilterIcon isActive={isActive}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polyline points="0,13 10,4 18,12 29,3 " strokeWidth="3" />
        <rect className="" x="1" y="24" width="4" height="6" fill="#a6a6a6" rx="1" />
        <rect className="" x="8.8" y="16" width="4" height="14" fill="#a6a6a6" rx="1" />
        <rect className="" x="15.8" y="24" width="4" height="6" fill="#a6a6a6" rx="1" />
        <rect className="" x="22.8" y="16" width="4" height="14" fill="#a6a6a6" rx="1" />
      </svg>
    </StyledFilterIcon>
  );
};

interface FilterPanelContainerProps {
  out: boolean;
}

const fadeIn = keyframes`
  from {
    transform-origin: top left;
    transform: scale(.25);
    opacity: -1;
  }

  to {
    transform-origin: top left;
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform-origin: top left;
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform-origin: top left;
    transform: scale(.25);
    opacity: -1;
  }
`;

const FilterPanelContainer = styled.div<FilterPanelContainerProps>`
  display: block;
  visibility: ${(props) => (props.out ? 'hidden' : 'visible')};
  animation: ${(props) => (props.out ? fadeOut : fadeIn)} 0.3s ease-in-out;
  transition: visibility 0.3s ease-in-out;
  position: absolute;
  left: 0px;
  z-index: 1000;
  background-color: var(--body);
  border: 1px solid var(--body);
  border-radius: 1rem;
  padding: 15px;
  margin: 0.4rem 0 0 0.4rem;
  min-width: 300px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
`;

const AddingFilterContainer = styled.div`
  position: relative;
  > .actions {
    display: flex;
    flex-direction: row-reverse;
  }
`;

function useOutsideAlerter(ref, callBack) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callBack();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}

export interface IFilterContainerProps {
  onChange: Function;
  disabledFilters: string[];
  filterReportType?: string;
  trendFilterType?: string;
  setTrendValue?: number;
}
/**
 * Func to handle different filters
 * @param onChange - Func to handle onChange
 * @param disabledFilters - Filters that will be disabled and will not appear
 * @param filterReportType - The filterReportType
 * @param trendFilterType - What is this
 * @returns
 */
export const FilterContainer = ({
  onChange,
  disabledFilters,
  filterReportType,
  trendFilterType,
}: IFilterContainerProps) => {
  //useState Hooks
  const [andOr, setAndOr] = useState<string>('and');
  const [assetFilters, setAssetFilters] = useState<string[]>(
    JSON.parse(localStorage.getItem('assetFilters')),
  );
  const [temporaryFilters, setTemporaryFilters] = useState<string[]>([]);
  const [filters, setFilters] = useState<string[]>([]);
  const [newFilter, setNewFilter] = useState<string>(null);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isAddingFilter, setIsAddingFilter] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [selectedFilterOption, setSelectedFilterOption] = useState<string | null>(null);
  const [reset, setReset] = useState<boolean>(false);
  const [invisible, setInvisible] = useState<boolean>(true);
  const [trendValue, setTrendValue] = useState<number>(0);
  const [andVariant, setAndVariant] = useState<string>('primary');
  const [orVariant, setOrVariant] = useState<string>('secondary');

  //useRefs
  const wrapperRef = useRef(null);

  //useEffect to get assetFilters from localStorage and to set them using a useState hook
  useEffect(() => {
    setAssetFilters(
      JSON.parse(localStorage.getItem('assetFilters')).filter((item: any) => {
        return !disabledFilters.some((value: any) => item.includes(value));
      }),
    );

    setIsValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isValid === true) {
      let arr = assetFilters.concat(temporaryFilters);
      setFilters(arr);
      onChange(arr, andOr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, assetFilters, temporaryFilters, andOr, selectedFilterOption]);

  useEffect(() => {
    newFilter === null ? setIsDisabled(true) : setIsDisabled(false);
  }, [newFilter]);

  useEffect(() => {
    // Check if the selected filter option is 'Active Assets' or 'Inactive Assets'
    // and update the isDisabled state accordingly.
    if (selectedFilterOption === 'Active Assets' || selectedFilterOption === 'Inactive Assets') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedFilterOption]);

  /**
   * Func to handle filter option change
   * @remarks
   * Currently is not being in the AssetFilter component??
   * @param option - The options
   */
  const handleFilterOptionChange = (option: string) => {
    setSelectedFilterOption(option);
  };

  /**
   * Func to change the btn icon style and to show the dropdown.
   */
  const handleAssetFilterBtnClick = () => {
    setReset(true);
    setIsActive((prevState) => !prevState);
    setIsAddingFilter(false);
    setInvisible((prevState) => !prevState);
  };

  /**
   * Func that will be called when user click anywhere in window
   * @remarks Appears to always be listening for mousedown events
   */
  const handleOutsideClick = () => {
    setReset(true);
    setIsActive(false);
    setIsAddingFilter(false);
    setInvisible(true);
  };

  const onAddFilter = () => {
    setReset(true);
    const filtersArr = [...temporaryFilters];
    filtersArr.push(newFilter);
    setTemporaryFilters(filtersArr);
    setIsAddingFilter(false);
    setNewFilter(null);
  };

  const onCancel = () => {
    setReset(true);
    setIsAddingFilter(false);
  };

  const handleFilterChange = (filter) => {
    setReset(false);
    setIsAddingFilter(true);
    setNewFilter(filter);
  };

  const handleRemoveFilter = (index, type) => {
    let filtersArr = [];
    switch (type) {
      case 'assetFilter':
        filtersArr = assetFilters.filter((item, key) => key !== index);
        setAssetFilters(filtersArr);
        break;
      case 'temporaryFilter':
        filtersArr = temporaryFilters.filter((item, key) => key !== index);
        setTemporaryFilters(filtersArr);
        break;
    }
  };

  useOutsideAlerter(wrapperRef, handleOutsideClick);

  return (
    <div className="asset-filter-container" ref={wrapperRef}>
      {/* Rectangular Asset Filter Dropdown indicator / icon */}
      <button
        type="button"
        className="asset-filter-container-btn"
        onClick={handleAssetFilterBtnClick}
      >
        <span>
          {filterReportType === 'asset-filter' ? (
            <div className="text-nowrap">
              <AssetFilterIcon isActive={isActive} />{' '}
              <Badge className="filter-badge">{filters.length}</Badge>
            </div>
          ) : (
            <div className="text-nowrap">
              <TrendFilterIcon isActive={isActive} />{' '}
              <Badge className="filter-badge">{filters.length}</Badge>
            </div>
          )}
        </span>
      </button>

      {/* The filter control dropdown. */}
      <FilterPanelContainer className="asset-filter-direction" out={invisible}>
        <div className="filter-panel-inner">
          <span className="filter-by">
            {filterReportType === 'asset-filter' ? 'Filter By:' : 'Filter By Trend:'}
          </span>
          {filterReportType === 'asset-filter' && (
            <div>
              <Button
                variant={andVariant}
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  setAndOr('and');
                  setAndVariant('primary');
                  setOrVariant('secondary');
                }}
              >
                And
              </Button>
              &nbsp;
              <Button
                variant={orVariant}
                size="sm"
                onClick={(e) => {
                  setAndOr('or');
                  setAndVariant('secondary');
                  setOrVariant('primary');
                }}
              >
                Or
              </Button>
            </div>
          )}
        </div>
        {assetFilters.map((filter, index) => {
          return (
            <Filter
              key={index}
              variant="primary"
              filter={filter}
              onRemoveFilter={() => handleRemoveFilter(index, 'assetFilter')}
            />
          );
        })}
        {temporaryFilters.map((filter, index) => {
          return (
            <Filter
              key={index}
              variant="pink"
              filter={filter}
              onRemoveFilter={() => handleRemoveFilter(index, 'temporaryFilter')}
            />
          );
        })}
        <AddingFilterContainer>
          {filterReportType === 'asset-filter' && (
            <AssetFilter
              onChange={handleFilterChange}
              onFilterOptionChange={handleFilterOptionChange}
              disabled={false}
              disabledFilters={disabledFilters.map((item) => item.replace('(', ''))}
              direction="column"
              idDirection="column"
              reset={reset}
            />
          )}
          {filterReportType === 'trend-filter' && (
            <TrendFilter trendFilterType={trendFilterType} setTrendValue={trendValue} />
          )}
          {isAddingFilter === true && (
            <div className="actions">
              <Button disabled={isDisabled} type="submit" size={'sm'} onClick={onAddFilter}>
                Save
              </Button>
              &nbsp;
              <Button className="primary" size={'sm'} onClick={onCancel}>
                Cancel
              </Button>
            </div>
          )}
        </AddingFilterContainer>
      </FilterPanelContainer>
    </div>
  );
};
