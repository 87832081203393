import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// #region TYPES
const USER_PREFS_SET_TEMP = 'USER_PREFS_SET_TEMP';
const USER_PREFS_SET_ASSETFILTERS = 'USER_PREFS_SET_ASSETFILTERS';
const USER_PREFS_INITIALIZE = 'USER_PREFS_INITIALIZE';
// #endregion TYPES

const initState = {
  temp: 'Celsius', // Default value
  assetFilters: [],
};

export function userPrefsSetTempAction(temp) {
  return {
    type: USER_PREFS_SET_TEMP,
    temp,
  };
}

export function userPrefsInitializeAction(temp, assetFilters) {
  return {
    type: USER_PREFS_INITIALIZE,
    payload: { temp, assetFilters },
  };
}

function handleSetTemp(state, { temp }) {
  return {
    ...state,
    temp,
  };
}

function handleInitialize(state, { payload }) {
  return {
    ...state,
    temp: payload.temp,
    assetFilters: payload.assetFilters || state.assetFilters,
  };
}

export function userPrefsSetAssetFiltersAction(assetFilters) {
  return {
    type: USER_PREFS_SET_ASSETFILTERS,
    assetFilters,
  };
}

function handleSetAssetFilters(state, { assetFilters }) {
  return {
    ...state,
    assetFilters,
  };
}

const actionHandler = {
  [USER_PREFS_SET_TEMP]: handleSetTemp,
  [USER_PREFS_SET_ASSETFILTERS]: handleSetAssetFilters,
  [USER_PREFS_INITIALIZE]: handleInitialize,
};

const reducer = (state = initState, action) => {
  const handlerFn = actionHandler[action.type];

  if (!handlerFn) return state;

  return handlerFn(state, action);
};

// Persist the userPrefs reducer
const persistConfig = {
  key: 'userPrefs',
  storage,
};

export default persistReducer(persistConfig, reducer);
