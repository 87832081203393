import './CreateMap.scss';
import { useEffect, useState } from 'react';
import { Button, Card, Form, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { useMutation } from 'react-query';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropzone, Icon, Keywords, Loading, Text, toast } from '../../../components';
import { Api } from '../../../services';

async function createMap(data) {
  return Api.post('v2/maps', data);
}

function CreateMap({ app }) {
  const history = useHistory();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [keywords, setKeywords] = useState<any>([]);
  const [isGeographic, setIsGeographic] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [cache, setCache] = useState({});
  const [query, setQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);

  // Azure authentication
  const subscriptionKey = 'O-zauvwSjXNEVj6ByknZEFZgde3N6Nz2k1QRW-wYz1w';
  const baseURL = 'https://atlas.microsoft.com/';

  const createMapMutation = useMutation(createMap);

  useEffect(() => {
    if (!isGeographic) {
      setLatitude(0);
      setLongitude(0);
      setOptions([]);
      setCache({});
    }
  }, [isGeographic]);

  const onSubmit = async () => {
    setLoading(true);

    const mapData = [
      { key: 'applicationId', value: app.id },
      { key: 'name', value: name },
      { key: 'description', value: description },
      { key: 'isGeographic', value: isGeographic },
      { key: 'latitude', value: latitude },
      { key: 'longitude', value: longitude },
    ];

    const formData = new FormData();
    keywords.forEach((item) => mapData.push({ key: 'keywords', value: item }));
    mapData.forEach((item) => formData.append(item.key, item.value));
    files.forEach((file) => formData.append('image', file));

    try {
      // create map and upload images
      await createMapMutation.mutateAsync(formData, {
        onSuccess: async (data) => {
          setLoading(false);
          toast.success('Successfully created map.');
          history.push(`/mapbeta/` + data.headers['location'].split('/').slice(-1));
        },
      });
    } catch (error) {
      toast.error('Failed to create map. Please try again.');
      setLoading(false);
    }
  };

  const getResults = async (call) => {
    const resp = await Api.get<any>(call);
    const next = `${baseURL}search/poi/json?api-version=1.0&query=${
      resp.data.summary.query
    }&limit=15&ofs=${resp.data.summary.offset + 10}&subscription-key=${subscriptionKey}`;
    const total = resp.data.summary.totalResults;
    const newItems = resp.data.results.map((item) => ({
      name: item.address.freeformAddress,
      country: item.address.country,
      countrySubdivision: item.address.countrySubdivisionName,
      position: item.position,
    }));
    return { newItems, next, total };
  };

  const handleSearch = (value) => {
    setQuery(value);
    // if cached query items are available then set those as the initial options
    if (cache[value]) {
      setOptions(cache[value].items);
    } else {
      const initCall = `${baseURL}search/address/json?api-version=1.0&query=${value}&limit=15&ofs=0&subscription-key=${subscriptionKey}`;
      // get and cache new query items
      setIsLoading(true);
      getResults(initCall).then((resp) => {
        setOptions(resp.newItems);
        setCache({
          ...cache,
          [value]: { items: resp.newItems, next: resp.next, total: resp.total },
        });
        setIsLoading(false);
      });
    }
  };

  const handlePagination = () => {
    // if query cache length is less than total
    // continue to fetch new items on pagination using cached next api call
    if (cache[query].items.length !== cache[query].total) {
      setIsLoading(true);
      getResults(cache[query].next).then((resp) => {
        const items = cache[query].items.concat(resp.newItems);
        setOptions(items);
        setCache({ ...cache, [query]: { items: items, next: resp.next, total: resp.total } });
        setIsLoading(false);
      });
    }
  };

  const handleLocation = (selected) => {
    if (selected.length > 0) {
      setLatitude(selected[0].position.lat);
      setLongitude(selected[0].position.lon);
    } else {
      setLatitude(null);
      setLongitude(null);
    }
  };

  const renderSubmitBtn = () => {
    return (
      <div className="w-100 d-flex justify-content-center">
        <Button variant="primary" disabled={!name || !files.length} onClick={onSubmit}>
          Submit
        </Button>
      </div>
    );
  };

  const onDrop = (files) => {
    console.log(files);
    setFiles(files);
  };

  const renderTooltip = (tip) => <Tooltip id="button-tooltip">{tip}</Tooltip>;

  if (loading) return <Loading />;

  return (
    <Card className="h-100" style={{ overflowY: 'auto' }}>
      <Card.Header className="d-flex flex-row justify-content-left align-items-center">
        <div
          className="icon-wrap"
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'var(--primary)',
            borderRadius: '60px',
            padding: '0.5em 0.5em',
            textAlign: 'right',
          }}
        >
          <i
            className={`sonarIcon sonarIcon-map`}
            style={{ color: 'var(--white)', cursor: 'auto', fontWeight: 'normal' }}
          />
        </div>{' '}
        <Text className="fs-lg fw-bold" style={{ color: 'var(--dark)' }}>
          &nbsp; Create New Map
        </Text>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label className="fs-md fw-bold" style={{ color: 'var(--dark)' }}>
              Name
            </Form.Label>
            <Form.Control
              type="text"
              value={name}
              placeholder="Enter map name"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="description">
            <Form.Label className="fs-md fw-bold" style={{ color: 'var(--dark)' }}>
              Description
            </Form.Label>
            <Form.Control
              as="textarea"
              value={description}
              placeholder="Enter map description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <div className="mb-3 d-flex justify-content-around align-items-center">
            <Form.Group>
              <Form.Label className="fs-md fw-bold" style={{ color: 'var(--dark)' }}>
                Keywords
              </Form.Label>
              <Keywords isAddingKeyword onChange={(keywords) => setKeywords(keywords)} />
            </Form.Group>
            <Form.Group>
              <div className="d-flex align-items-center">
                <Form.Label className="fs-md fw-bold" style={{ color: 'var(--dark)' }}>
                  Geospatial
                </Form.Label>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'Turning on geospatial will allow you to place your map at its real world coordinates.',
                  )}
                >
                  <span className="d-inline-block">
                    <div className="icon-wrap">
                      <Icon
                        name="info"
                        className="dark-icon mb-2"
                        variant="light"
                        size="sm"
                        disabled={false}
                        showPointer={false}
                      />
                    </div>
                  </span>
                </OverlayTrigger>
              </div>
              <Form.Check
                className="fs-lg"
                type="switch"
                id="custom-switch"
                value={isGeographic.toString()}
                onChange={(e) => {
                  setIsGeographic(Boolean(e.target.checked));
                }}
              />
            </Form.Group>
          </div>
          {isGeographic && (
            <Form.Group className="mb-3" controlId="name">
              <Form.Label className="fs-md fw-bold" style={{ color: 'var(--dark)' }}>
                Location
              </Form.Label>
              <AsyncTypeahead
                className={'w-100'}
                delay={500}
                id="identifier"
                isLoading={isLoading}
                inputProps={{ style: { width: '100%' } }}
                onPaginate={handlePagination}
                maxResults={10}
                minLength={1}
                options={
                  options &&
                  options.map((option) => {
                    return {
                      label: option.name,
                      country: option.country,
                      countrySubdivision: option.countrySubdivision,
                      position: option.position,
                    };
                  })
                }
                onSearch={handleSearch}
                onChange={handleLocation}
                paginate
                placeholder="Search for a location to place this map"
                renderMenuItemChildren={(option, { text }) => (
                  <div title={option.label}>
                    <Highlighter search={text}>{option.label}</Highlighter>
                    <div>
                      <small className="filter-sm-name">{option.country}</small>
                      <small className="filter-sm-name"> - {option.countrySubdivision}</small>
                    </div>
                  </div>
                )}
                useCache={false}
              />
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <Dropzone className="mb-3" accept="image/*" multiple onDrop={onDrop} />
          </Form.Group>
        </Form>
        {renderSubmitBtn()}
      </Card.Body>
    </Card>
  );
}

const mapStateToProps = ({ app }) => ({
  app: app.app,
});

export default connect(mapStateToProps)(CreateMap);
