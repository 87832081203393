import '../../../../scss/variables.scss';
import './EditCellRenderer.scss';
import { isAdmin, isSuperAdmin, isSystemAdmin } from '@utils';
import { useHistory } from 'react-router-dom';
import Icon from '../../../Icon/Icon';

function V2EditCellRenderer({ data, onClickDelete }) {
  const history = useHistory();
  const authorized = isAdmin() || isSystemAdmin() || isSuperAdmin();

  const onClickEdit = () => {
    history.push(`/mapbeta/${data.mapId}/edit`);
  };

  return (
    <div className="favorite-edit">
      {authorized && (
        <>
          <Icon
            name="edit"
            className="edit align-sub primary-icon"
            variant="light"
            disabled={false}
            showPointer={true}
            size="lg"
            onClick={onClickEdit}
          />
          <Icon
            name="trash"
            size="lg"
            disabled={false}
            showPointer={true}
            onClick={onClickDelete}
            className="edit danger-icon"
            variant="light"
          />
        </>
      )}
    </div>
  );
}

export default V2EditCellRenderer;
