import '../../MapMenu.scss';
import { Api } from '@services';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { BeaconReadingsPager, Text } from '../../..';

async function fetchReadings({ feature }) {
  if (feature.properties.EntityType === 'Asset') {
    const { data } = await Api.get(`/v2/assets/${feature.properties.Id}/readings`);
    return data;
  } else {
    return null;
  }
}

export function MapInfoItem({ mapName, feature, selected, setShowMore }) {
  const [readings, setReadings] = useState<any>(null);
  const history = useHistory();

  const readingsRes = useQuery(
    [`readings-${feature.properties.Id}`],
    () => fetchReadings({ feature }),
    {
      enabled: feature.properties.EntityType === 'Asset',
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setReadings(data);
      },
    },
  );

  return (
    <div>
      <div className="object-wrapper-expand">
        <div className="d-flex flex-row justify-content-between back-button">
          {(selected === 'asset' || selected === 'stationary') && (
            <Button
              title="Go to Asset"
              className="btn-light d-flex justify-content-between align-items-center nav-text-icon"
              variant="light"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                history.push('/assets/' + feature.properties.Id);
                e.stopPropagation();
              }}
            >
              <i className={`sonarIcon sonarIcon-open-in fs-md`} />
            </Button>
          )}
          {selected === 'place' && (
            <Button
              title="Go to Place"
              className="btn-light d-flex justify-content-between align-items-center nav-text-icon"
              variant="light"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                history.push('/places/' + feature.properties.Id);
                e.stopPropagation();
              }}
            >
              <i className={`sonarIcon sonarIcon-open-in fs-md`} />
            </Button>
          )}
          <Button
            title={selected ? 'Back' : 'Close'}
            className="btn-light d-flex flex-row align-items-center justify-content-between nav-text-icon"
            variant="light"
            onClick={() => {
              setShowMore(null);
            }}
          >
            {selected ? (
              <i className="sonarIcon sonarIcon-left-arrow fs-md" />
            ) : (
              <i className="sonarIcon sonarIcon-add sonarIcon-rotate-45 fs-md nav-text-icon" />
            )}
          </Button>
        </div>

        <BeaconReadingsPager
          type={feature.properties.EntityType}
          beacons={readings}
          name={feature.properties.Name}
          id={feature.properties.Id}
          mapName={mapName}
          placeName={feature.properties.LastPlaceName}
          feature={feature}
        />
      </div>
    </div>
  );
}
