import './../../../scss/comps/Navigation.scss';
import './../../../scss/variables.scss';
import { Text } from '@components';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { store } from '../../../redux';
import { isAdmin, isLegacyAuthEnabled, isSystemAdmin } from '../../../utils';

function Navigation() {
  const { navigation } = store.getState();
  const [systemAdminActive, setSystemAdminActive] = useState(false);
  const isActive = (pathname) => {
    return new RegExp(pathname).test(window.location.pathname);
  };

  const toggleSystemAdminSubMenu = () => {
    setSystemAdminActive(!systemAdminActive);
  };

  // Admin only links
  const renderAdminLinks = () => {
    if (!isAdmin()) return null;

    if (!isLegacyAuthEnabled()) return null;

    return (
      <Fragment>
        <NavLink
          iconName="users"
          text="User Management"
          to="/admin/user-management"
          isActive={isActive('/admin/user-management')}
          hideAdminPortalChildren={setSystemAdminActive}
        />
      </Fragment>
    );
  };

  // System Admin Only Links
  const renderSystemAdminLinks = () => {
    if (!isSystemAdmin()) return null;

    if (!isLegacyAuthEnabled()) return null;

    return (
      <Fragment>
        <NavLink
          text="Admin Portal"
          iconName="user-c-frame-2"
          toggleIcon="down-arrow"
          to="/admin/app"
          isActive={isActive('/admin/app') || isActive('/admin/users')}
          togglePortalLinks={toggleSystemAdminSubMenu}
          systemAdminActive={systemAdminActive}
        />
        {systemAdminActive && (
          <>
            <NavLink
              text="Apps"
              iconName="app-store"
              to="/admin/app"
              isActive={isActive('/admin/app')}
              children={true}
            />
            <NavLink
              text="Users"
              iconName="users"
              to="/admin/users"
              isActive={isActive('/admin/users')}
              children={true}
            />
          </>
        )}
      </Fragment>
    );
  };
  // #endregion HELPER FUNCTIONS

  return (
    <nav
      data-testid="main-nav"
      className={`sidenav ${navigation.navWidth === '275px' ? 'opened' : 'closed'}`}
    >
      <div className="nav-links">
        <NavLink
          iconName="dashboard"
          text="Dashboard"
          to="/dashboard"
          isActive={isActive('/dashboard')}
          hideAdminPortalChildren={setSystemAdminActive}
        />
        <NavLink
          iconName="trigger"
          text="Triggers"
          to="/triggers"
          isActive={isActive('/triggers')}
          hideAdminPortalChildren={setSystemAdminActive}
        />
        <NavLink
          iconName="map"
          text="Maps"
          to="/maps"
          isActive={
            isActive('/assets') && isActive('/maps')
              ? false
              : isActive('/maps/create')
              ? false
              : isActive('/maps')
              ? true
              : false
          }
          hideAdminPortalChildren={setSystemAdminActive}
        />
        <NavLink
          iconName="map"
          text="Maps Beta"
          to="/mapbeta"
          isActive={isActive('/mapbeta') || isActive('/maps/create')}
          hideAdminPortalChildren={setSystemAdminActive}
        />
        <NavLink
          iconName="asset"
          text="Assets"
          to="/assets"
          isActive={isActive('/assets')}
          hideAdminPortalChildren={setSystemAdminActive}
        />
        <NavLink
          iconName="place"
          text="Places"
          to="/places"
          isActive={isActive('/places')}
          hideAdminPortalChildren={setSystemAdminActive}
        />
        <NavLink
          iconName="device"
          text="Device Management"
          to="/admin/device-management/Gateways"
          isActive={isActive('/admin/device-management')}
          hideAdminPortalChildren={setSystemAdminActive}
        />
        {false && (
          <NavLink
            iconName="fas fa-map-pin"
            text="Asset"
            to="/reports/assets"
            isActive={isActive('/reports/assets')}
            hideAdminPortalChildren={setSystemAdminActive}
          />
        )}
        <NavLink
          iconName="analytics"
          text="Analytics"
          to="/analytics/overview"
          isActive={isActive('/analytics')}
          hideAdminPortalChildren={setSystemAdminActive}
        />
        {/* Admin */}
        {renderAdminLinks()}
        {/* System Admin */}
        {renderSystemAdminLinks()}
      </div>
    </nav>
  );
}

// #region HELPER COMPS
function NavLinkHeader({ text }) {
  return <li className="nav-header">{text}</li>;
}

NavLinkHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

function NavLink({
  iconName,
  text,
  to,
  isAdminOnly = false,
  isActive = false,
  isDevOnly = false,
  envRegexString = '(prod|stage|dev|test|local)',
  togglePortalLinks,
  hideAdminPortalChildren,
  toggleIcon,
  children,
  systemAdminActive,
}) {
  const history = useHistory();
  const { navigation } = store.getState();
  const [hovered, setHovered] = useState(false);

  if (!new RegExp(envRegexString).test(window._sonar_env.REACT_APP_ENV)) return null;

  if (isAdminOnly && !isAdmin()) return null;

  const linkClassName = classnames('menu no-arrow', { open: isActive });

  //Allow page refresh of the same location
  const refreshPage = () => {
    window.location.pathname == to && window.location.reload();
  };

  // Admin Portal Child Links
  if (children) {
    return (
      <Button
        className={
          'd-flex align-items-center justify-content-right mt-1 w-100 p-2 pt-2 pb-2 float-end'
        }
        variant={'light'}
        onClick={() => {
          history.push(to);
        }}
      >
        <div
          className={`icon-wrap d-flex align-items-center p-2 text-end mx-3 ${
            isActive ? 'active' : 'not-active'
          }`}
        >
          <i
            className={`sonarIcon sonarIcon-${iconName} fs-sm cursor-pointer ${
              isActive ? 'active' : 'not-active'
            }`}
            disabled={false}
          />
        </div>
        <Text className={`"text-nowrap ps-2 ${isActive ? 'active' : 'not-active'}`}>{text}</Text>
      </Button>
    );
  } else if (text === 'Admin Portal') {
    return (
      <Button
        className={'d-flex align-items-center justify-content-left mt-3 w-100  pt-2 pb-2'}
        variant={'light'}
        onClick={() => {
          togglePortalLinks();
          history.push(to);
        }}
        onMouseEnter={() => {
          // Add mouse enter event handler
          setHovered(true);
        }}
        onMouseLeave={() => {
          // Add mouse leave event handler
          setHovered(false);
        }}
      >
        <div
          className={`icon-wrap d-flex align-items-center p-2 text-end mx-3 ${
            isActive ? 'active' : 'not-active'
          }`}
        >
          <i
            className={`sonarIcon sonarIcon-${iconName} fs-md cursor-pointer ${
              isActive ? 'active' : 'not-active'
            }`}
            disabled={false}
          />
        </div>
        <Text className={`text-nowrap ps-1 ${isActive ? 'active' : 'not-active'}`}>{text}</Text>
        <div className="icon-wrap d-flex align-items-center p-2 text-end mx-3">
          <i
            className={`sonarIcon sonarIcon-${toggleIcon} fs-sm cursor-pointer ${
              systemAdminActive ? 'active-sys-admin' : hovered ? 'active-sys-admin-hovered' : ''
            }`}
            disabled={false}
          />
        </div>
      </Button>
    );
  } else {
    return (
      <Button
        className={
          'navigation-pad d-flex align-items-center justify-content-left mt-3 w-100 pt-2 pb-2'
        }
        variant={'light'}
        onClick={() => {
          hideAdminPortalChildren(false);
          refreshPage();
          history.push(to);
        }}
      >
        <div
          className={`icon-wrap d-flex align-items-center p-2 text-end mx-3 ${
            isActive ? 'active' : 'not-active'
          }`}
        >
          <i
            className={`sonarIcon sonarIcon-${iconName} fs-md cursor-pointer ${
              isActive ? 'active' : 'not-active'
            }`}
            disabled={false}
          />
        </div>
        <Text className={`"text-nowrap ps-1 ${isActive ? 'active' : 'not-active'}`}>{text}</Text>
      </Button>
    );
  }
}

NavLink.propTypes = {
  iconName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,

  // defaults
  isAdminOnly: PropTypes.bool,
  isActive: PropTypes.bool,
  isDevOnly: PropTypes.bool,
  envRegexString: PropTypes.string,
};
// #endregion HELPER COMPS

export default Navigation;
