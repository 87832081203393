import * as L from 'leaflet';
import { noop } from '../../../../utils';
import {} from '../leaflet-icons';

const createBeaconMarker = (beacon, { reading }) => {
  if (beacon.location) {
    let markerColor = 'cadetblue';
    let value = '';

    if (beacon.beacon?.name) {
      markerColor = beacon?.beacon[reading]?.activeTrigger ? 'green' : 'red';
      value = Math.round(beacon.beacon[reading]?.mean?.toFixed(1));
    }

    let marker = L.marker([beacon.location.y, beacon.location.x], {
      icon: L.divIcon({
        className: 'awesome-marker-icon awesome-marker',
        html: `<div class="icon-white pt-2">${value ? value : 'N/A'}</div>`,
        iconSize: [35, 45],
      }),
      title: beacon.beacon?.name,
    });

    marker.beaconIndex = beacon.index;
    beacon.beacon?.name && marker.bindPopup(beacon.beacon?.name);

    if (beacon.beacon?.name) {
      marker.on('click', (e) => {
        e.data = beacon;
        e.marker = marker;

        // Function to format the value to show only two decimal places
        const formatValue = (value) => {
          const floatValue = parseFloat(value);
          const formattedValue = floatValue.toFixed(2);
          return parseFloat(formattedValue).toString();
        };

        const name = beacon.beacon.name.replace("'", '&#8217;');

        // Generating HTML for popup
        let popupContent = `<br/><div class=''>`;

        // Loop over readings array
        beacon.beacon.readings.forEach((reading) => {
          popupContent += `<div class=''>
                           <b>${reading.ability}:</b>
                            <span class='col-6'>${formatValue(reading.value)}</span>
                          </div>`;
        });

        popupContent += `
        <a class='btn-light  justify-content-left align-items-center' href='#' onclick="CBCopy('${name}')">
                          <i class="col-1 far fa-lg fa-copy"></i>
                        </a>${name}</div>`;

        e.marker.bindPopup(popupContent);

        if (beacon.onmouseover) beacon.click(e);
        if (beacon.autoPopup) marker.openPopup();
      });
    }

    return marker;
  }
  return null;
};

const createBeaconLayers = (beacon, { reading }) => {
  const layers = [];
  if (beacon.isVisible) {
    let layer = createBeaconMarker(beacon, { reading });

    if (layer != null) {
      layer.data = beacon;
      layers.push(layer);
    }
  }

  return layers;
};

const createBeaconsLayerGroup = (beacons, { reading = '', onSetLayers = noop } = {}) => {
  return new L.FeatureGroup(createBeaconsLayerArray(beacons, { reading, onSetLayers }));
};

const createBeaconsLayerArray = (beacons, { reading, onSetLayers }) => {
  if (!beacons) {
    return [];
  }

  const layers = [];
  for (let beacon of beacons) {
    const beaconLayers = createBeaconLayers(beacon, { reading, onSetLayers });
    onSetLayers(beaconLayers);

    for (let layer of beaconLayers) {
      layers.push(layer);
    }
  }

  return layers;
};

export { createBeaconsLayerGroup };
