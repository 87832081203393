import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { FavoriteTab } from './FavoriteTab';
import { RecentlyViewedTab } from './RecentlyViewedTab';
import { Api, favoriteService } from '../../services';
import { useApp } from '../../react';
import { store, setFavoritesAction } from '../../redux';
import { useDataRefresh } from '../../context/DataRefreshContext';

async function fetchFavorites({ appId }) {
  if (!appId) return [];
  const { data } = await favoriteService.getFavorites(appId);
  const group = data.reduce((acc, item) => {
    if (!acc[item.itemType]) {
      acc[item.itemType] = [];
    }
    acc[item.itemType].push(item);
    return acc;
  }, {});
  store.dispatch(setFavoritesAction(group));
  return group;
}

async function fetchRecents({ appId }) {
  if (!appId) return [];
  const { data } = await Api.get(`/users/recentlyviewed/${appId}`);
  return data;
}

function useOutsideAlerter(ref, callBack) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (!ref.current.contains(event.target)) {
        callBack();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
}

function Favorites({ defaultActiveKey, onDone }) {
  const app = useApp();
  const wrapperRef = useRef(null);
  const [reload, setReload] = useState<boolean>(false);
  const favoritesRes = useQuery<any>(['home-favorites', reload], () =>
    fetchFavorites({ appId: app?.id }),
  );
  const recentsRes = useQuery<any>(['home-recents'], () => fetchRecents({ appId: app?.id }));
  const { refresh } = useDataRefresh();
  const history = useHistory();

  useOutsideAlerter(wrapperRef, onDone);

  const onClickItem = (item) => {
    const route = `/${item.itemType.toLowerCase()}s/${item.itemId}`;
    history.push(route);
    window.location.reload();
  };

  const unfavoriteAsset = async (assetId) => {
    favoritesRes.data.Asset.splice(
      favoritesRes.data.Asset.findIndex((elem) => elem['itemId'] == assetId),
      1,
    );
    await favoriteService.unfavoriteAsset(assetId);
    setReload(!reload);
  };

  const unfavoriteMap = async (mapId) => {
    favoritesRes.data.Map.splice(
      favoritesRes.data.Map.findIndex((elem) => elem['itemId'] == mapId),
      1,
    );
    await Api.delete(`/maps/${mapId}/favorites`);
    setReload(!reload);
    refresh();
  };

  const unfavoritePlace = async (placeId) => {
    favoritesRes.data.Place.splice(
      favoritesRes.data.Place.findIndex((elem) => elem['itemId'] == placeId),
      1,
    );
    await favoriteService.unfavoritePlace(placeId);
    setReload(!reload);
  };

  return (
    <div className="w-100 h-100" ref={wrapperRef}>
      <Accordion defaultActiveKey={defaultActiveKey}>
        {!favoritesRes.isLoading && favoritesRes.isSuccess && (
          <>
            <FavoriteTab
              eventKey={'0'}
              favorites={favoritesRes.data.Asset?.length > 0 ? favoritesRes.data.Asset : []}
              onClickItem={(e) => onClickItem(e)}
              onDone={() => onDone()}
              tabName={'Asset'}
              colorClass={'primary-color'}
              handleRemove={(e) => {
                unfavoriteAsset(e.itemId);
              }}
            />
            <FavoriteTab
              eventKey={'1'}
              favorites={favoritesRes.data.Map?.length > 0 ? favoritesRes.data.Map : []}
              onClickItem={(e) => onClickItem(e)}
              onDone={() => onDone()}
              tabName={'Map'}
              colorClass={'alt-color'}
              handleRemove={(e) => {
                unfavoriteMap(e.itemId);
              }}
            />
            <FavoriteTab
              eventKey={'2'}
              favorites={favoritesRes.data.Place?.length > 0 ? favoritesRes.data.Place : []}
              onClickItem={(e) => onClickItem(e)}
              onDone={() => onDone()}
              tabName={'Place'}
              colorClass={'tertiary-color'}
              handleRemove={(e) => {
                unfavoritePlace(e.itemId);
              }}
            />
          </>
        )}

        {!recentsRes.isLoading && recentsRes.isSuccess && (
          <RecentlyViewedTab
            eventKey={'3'}
            items={recentsRes.data?.length > 0 ? recentsRes.data : []}
            onClickItem={(e) => onClickItem(e)}
            refresh={() => setReload(!reload)}
            reset={reload}
          />
        )}
      </Accordion>
    </div>
  );
}

export default Favorites;
