import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Api, favoriteService } from '../../../../services';
import { isAdmin, isSuperAdmin, isSystemAdmin } from '../../../../utils';
import { Icon } from '../../..';

export function MapInfoTitle({ app, mapId, mapTitle, reset }) {
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const history = useHistory();
  const authorized = isAdmin() || isSystemAdmin() || isSuperAdmin();

  useEffect(() => {
    checkIsFavorite(app, mapId);
  }, []);

  const checkIsFavorite = async (app, id) => {
    const { data } = await favoriteService.getFavorites(app.id);
    const isFavorite = data.find((fav) => fav.itemType === 'Map' && fav.itemId.toString() === id);
    isFavorite ? setIsFavorite(true) : setIsFavorite(false);
  };

  const favoriteMap = async () => {
    setIsFavorite(true);
    return Api.post(`/maps/${mapId}/favorites`);
  };

  const unfavoriteMap = async () => {
    setIsFavorite(false);
    return Api.delete(`/maps/${mapId}/favorites`);
  };

  let searchClasses = classNames({
    'search-bar': !showSearch,
    'search-bar-expand': showSearch,
  });

  return (
    <div className="title-bar">
      <Card.Header>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex flex-row justify-content-start align-items-center">
            <Icon
              name={isFavorite ? 'star-fill' : 'star-empty'}
              variant="light"
              size="md"
              className="align-sub primary-icon"
              disabled={false}
              showPointer={true}
              onClick={isFavorite ? unfavoriteMap : favoriteMap}
            />
            <h4 className="mb-0">{mapTitle}</h4>
          </div>
          <div className="d-flex flex-row justify-content-end align-items-center">
            {authorized && (
              <Icon
                name={'edit'}
                className="align-sub primary-icon"
                variant="light"
                disabled={false}
                size="md"
                showPointer={true}
                onClick={() => {
                  reset();
                  history.push(mapId + '/edit');
                }}
              />
            )}
          </div>
        </div>
      </Card.Header>
    </div>
  );
}
