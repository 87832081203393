import { Api } from '@services';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { Loading, toast } from '../../../components';
import { MapObject } from '../../../components/MapsV2';
import { useApp } from '../../../react';

async function fetchMapImage({ mapId }) {
  const response = await Api.get(`v2/maps/${mapId}/image`);
  return response.config.url;
}

async function fetchMapOveraly({ mapId }) {
  const { data } = await Api.get(`v2/maps/${mapId}`);
  return data;
}

async function fetchMapGateways(mapId, payload) {
  const { data } = await Api.post(`v2/maps/${mapId}/gateways/filter`, payload);
  return data;
}

async function fetchMapPlaces(mapId, payload) {
  const { data } = await Api.post(`v2/maps/${mapId}/places/filter`, payload);
  return data;
}

async function fetchMapAssets(mapId, payload) {
  const { data } = await Api.post(`v2/maps/${mapId}/assets/filter`, payload);
  return data;
}

async function fetchMapStationary(mapId, payload) {
  const { data } = await Api.post(`v2/maps/${mapId}/stationary-assets/filter`, payload);
  return data;
}

export function MapDetailV2() {
  const app = useApp();
  const location = useLocation();
  const urlArr = location.pathname.split('/');
  const mapId =
    urlArr[urlArr.length - 1] === 'edit' ? urlArr[urlArr.length - 2] : urlArr[urlArr.length - 1];

  // Map feature collections
  const [mapImg, setMapImg] = useState<any>(null);
  const [newMapImg, setNewMapImg] = useState<any>(null);
  const [overlayFC, setMapOverlayFC] = useState<any>(null);
  const [placeFC, setPlaceFC] = useState<any>(null);
  const [gatewayFC, setGatewayFC] = useState<any>(null);
  const [assetFC, setAssetFC] = useState<any>(null);
  const [stationaryFC, setStationaryFC] = useState<any>(null);
  const [scale, setScale] = useState<any>(null);
  const [placeList, setPlaceList] = useState<any>(null);
  const [assetList, setAssetList] = useState<any>(null);
  const [gatewayList, setGatewayList] = useState<any>(null);
  const [stationaryList, setStationaryList] = useState<any>(null);

  // Map data queries
  const initImg = useQuery([`map-${mapId}-image`], async () => await fetchMapImage({ mapId }), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setMapImg(data);
    },
  });

  const mapOverlayRes = useQuery([`map-${mapId}-overlay`], () => fetchMapOveraly({ mapId }), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setMapOverlayFC(data);
    },
  });

  const cancelToken = Api.CancelToken;
  let fetchDataCancel;

  const reset = () => {
    initImg.refetch();
    mapOverlayRes.refetch();
    setAssetList(null);
    setAssetFC(null);
    setPlaceList(null);
    setPlaceFC(null);
    setGatewayList(null);
    setGatewayFC(null);
    setStationaryList(null);
    setStationaryFC(null);
    setScale(null);
  };

  const resetAsset = () => {
    setAssetList(null);
    setAssetFC(null);
  };

  const resetPlace = () => {
    setPlaceList(null);
    setPlaceFC(null);
  };

  const resetGateway = () => {
    setGatewayList(null);
    setGatewayFC(null);
  };

  const resetStationary = () => {
    setStationaryList(null);
    setStationaryFC(null);
  };

  const saveMap = async () => {
    try {
      await Api.put(`/v2/maps/${mapId}`, overlayFC, {
        cancelToken: new cancelToken(function executor(c) {
          fetchDataCancel = c;
        }),
      });

      await Api.put(`/v2/maps/${mapId}/gateways`, gatewayFC, {
        cancelToken: new cancelToken(function executor(c) {
          fetchDataCancel = c;
        }),
      });

      await Api.put(`/v2/maps/${mapId}/places`, placeFC, {
        cancelToken: new cancelToken(function executor(c) {
          fetchDataCancel = c;
        }),
      });

      await Api.put(`/v2/maps/${mapId}/stationary-assets`, stationaryFC, {
        cancelToken: new cancelToken(function executor(c) {
          fetchDataCancel = c;
        }),
      });

      if (scale) {
        await Api.post(`/v2/maps/${mapId}/scale`, scale, {
          cancelToken: new cancelToken(function executor(c) {
            fetchDataCancel = c;
          }),
        });
      }

      // if (newMapImg) {
      //   let formData = new FormData();
      //   formData.append('file', newMapImg);
      //   await Api.put(`api/v2/maps/${mapId}/image`, formData, {
      //     cancelToken: new cancelToken(function executor(c) {
      //       fetchDataCancel = c;
      //     }),
      //   });
      // }
      reset();
      toast.success('Map was successfully updated!');
    } catch (error) {
      console.log(error);
      toast.error('Failed to update the current map.');
    }
  };

  if (initImg.isFetching || mapOverlayRes.isFetching || !overlayFC || !mapImg) return <Loading />;

  return (
    <>
      <MapObject
        app={app}
        mapId={mapId}
        urlArr={urlArr}
        mapImg={mapImg}
        overlayData={overlayFC}
        placeData={placeFC}
        gatewayData={gatewayFC}
        assetData={assetFC}
        stationaryData={stationaryFC}
        placeList={placeList}
        assetList={assetList}
        gatewayList={gatewayList}
        stationaryList={stationaryList}
        updateMapFC={(e) => setMapOverlayFC(e)}
        updatePlaceFC={(e) => setPlaceFC(e)}
        updateGatewayFC={(e) => setGatewayFC(e)}
        updateStationaryFC={(e) => setStationaryFC(e)}
        updateScale={(e) => setScale(e)}
        fetchAssetData={(e) => {
          fetchMapAssets(mapId, e).then((data) => {
            setAssetFC(data);
            setAssetList(data);
          });
        }}
        fetchPlaceData={(e) => {
          fetchMapPlaces(mapId, e).then((data) => {
            setPlaceFC(data);
            setPlaceList(data);
          });
        }}
        fetchGatewayData={(e) => {
          fetchMapGateways(mapId, e).then((data) => {
            setGatewayFC(data);
            setGatewayList(data);
          });
        }}
        fetchStationaryData={(e) => {
          fetchMapStationary(mapId, e).then((data) => {
            setStationaryFC(data);
            setStationaryList(data);
          });
        }}
        resetAsset={() => resetAsset()}
        resetPlace={() => resetPlace()}
        resetGateway={() => resetGateway()}
        resetStationary={() => resetStationary()}
        setMapImg={(e) => setNewMapImg(e)}
        reset={() => {
          reset();
        }}
        saveMap={() => saveMap()}
      />
    </>
  );
}
