import './AssetFilterContainer.scss';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { Icon } from '../components';
import { AssetFilter } from '../components/AssetFilter';

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr;
  justify-items: space-between;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  & > div {
    padding: 0;
  }
`;

const StyledButtonContainer = styled.div`
  display: grid;
  vertical-align: center;
  grid-template-columns: repeat(${(props) => props.children['length']}, 1fr);
  grid-gap: 1rem;
  min-width: 275px;
`;

export interface AssetFilterContainerProps {
  onChange: Function;
  defaultValue: string[];
  submit: boolean;
  onSubmit: Function;
}

export const AssetFilterContainer = ({
  onChange,
  defaultValue,
  submit,
  onSubmit,
}: AssetFilterContainerProps) => {
  const [filters, setFilters] = useState<string[]>([]);
  const [defaultFilters, setDefaultFilters] = useState<string[]>([]);
  const [enabledRow, setEnabledRow] = useState<number>(-1);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [modified, setModified] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [cancel, setCancel] = useState<boolean>(false);

  useEffect(() => {
    setFilters(defaultValue);
    setDefaultFilters(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (submitted) {
      setDefaultFilters(filters);
      onChange(filters, isDisabled, modified);
      onSubmit();
      setSubmitted(false);
    }
  }, [submitted]);

  useEffect(() => {
    if (!filters.includes(null)) {
      setEnabledRow(-1);
    }
  }, [submit]);

  const handleChange = (filter, index) => {
    setSubmitted(false);
    const filtersArr = [...filters];
    filtersArr[index] = filter;
    setFilters(filtersArr);
    filter === null ? setIsDisabled(true) : setIsDisabled(false);
    setModified(true);
  };

  const onAddFilter = () => {
    setIsDisabled(true);
    const filtersArr = filters ?? [...filters];
    filtersArr.push(null);
    setFilters(filtersArr);
    setEnabledRow(filtersArr.length - 1);
    setModified(true);
    onChange(filters, true, true);
  };

  const onEditFilter = (index) => {
    setCancel(false);
    setEnabledRow(index);
    setModified(true);
  };

  const onDeleteFilter = (index) => {
    setSubmitted(true);
    const filtersArr = filters.filter((item, key) => key !== index);
    setFilters(filtersArr);
    setEnabledRow(-1);
    setIsDisabled(false);
    setModified(false);
  };

  const onCancelEdit = () => {
    setCancel(true);
    setEnabledRow(-1);
    setIsDisabled(false);
    setModified(false);
    setFilters(defaultFilters);
  };

  const onSubmitFilter = () => {
    setSubmitted(true);
    setEnabledRow(-1);
    setIsDisabled(false);
    setModified(false);
  };

  const debounceInput = debounce((e, index) => handleChange(e, index), 1000);

  return (
    <ListGroup variant="flush" className="default-asset-filter-group">
      {filters?.map((value, index) => (
        <ListGroup.Item className="default-asset-filter" key={index}>
          <StyledContainer key={value}>
            <AssetFilter
              onChange={(e) => debounceInput(e, index)}
              initFilter={value}
              disabled={enabledRow !== index}
              disabledFilters={['all', 'device id', 'battery value']}
              direction="Column"
              idDirection="column"
            />
            {enabledRow !== index ? (
              <StyledButtonContainer>
                <Button
                  disabled={isDisabled}
                  variant="secondary"
                  onClick={() => onEditFilter(index)}
                >
                  Edit
                </Button>
              </StyledButtonContainer>
            ) : (
              <StyledButtonContainer>
                <Button
                  form="userPrefs"
                  disabled={isDisabled}
                  variant="primary"
                  onClick={onSubmitFilter}
                >
                  Add
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    onDeleteFilter(index);
                  }}
                >
                  Delete
                </Button>
                <Button disabled={isDisabled} variant="secondary" onClick={onCancelEdit}>
                  Cancel
                </Button>
              </StyledButtonContainer>
            )}
          </StyledContainer>
        </ListGroup.Item>
      ))}
      <div className="mt-3">
        <Icon
          disabled={isDisabled}
          variant="light"
          name="add"
          size="sm"
          className="sonarIcon-circle"
          showPointer={!isDisabled}
          onClick={onAddFilter}
        />
      </div>
    </ListGroup>
  );
};
