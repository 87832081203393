import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Api, favoriteService } from '../../../../services';
import { isAdmin, isSuperAdmin, isSystemAdmin } from '../../../../utils';
import { Icon, Text } from '../../..';

export function MapEditTitle({ app, mapId, mapName, setMapName, selected, reset, saveMap }) {
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  // const [showSearch, setShowSearch] = useState<boolean>(false);
  const history = useHistory();
  const MySwal = withReactContent(Swal);
  const authorized = isAdmin() || isSystemAdmin() || isSuperAdmin();

  useEffect(() => {
    checkIsFavorite(app, mapId);
  }, []);

  const checkIsFavorite = async (app, id) => {
    const { data } = await favoriteService.getFavorites(app.id);
    const isFavorite = data.find((fav) => fav.itemType === 'Map' && fav.itemId.toString() === id);
    isFavorite ? setIsFavorite(true) : setIsFavorite(false);
  };

  const favoriteMap = async () => {
    setIsFavorite(true);
    return Api.post(`/maps/${mapId}/favorites`);
  };

  const unfavoriteMap = async () => {
    setIsFavorite(false);
    return Api.delete(`/maps/${mapId}/favorites`);
  };

  return (
    <div className="title-bar">
      <Card.Header>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex flex-row justify-content-start align-items-center">
            <Icon
              name={isFavorite ? 'star-fill' : 'star-empty'}
              variant="light"
              size="md"
              className="align-sub primary-icon"
              disabled={false}
              showPointer={true}
              onClick={isFavorite ? unfavoriteMap : favoriteMap}
            />
            {selected === 'overlay' ? (
              <Text className="fw-bold fs-1x">
                <input
                  className="fw-bold"
                  id="mapName"
                  type="text"
                  name="map-name"
                  value={mapName}
                  onChange={(e) => {
                    setMapName(e);
                  }}
                />
              </Text>
            ) : (
              <Text className="fw-bold fs-1x">{mapName}</Text>
            )}
          </div>
          <div className="d-flex flex-row justify-content-end align-items-center">
            {authorized && (
              <div>
                <Icon
                  name={'floppy-disk'}
                  className="align-sub primary-icon"
                  variant="light"
                  disabled={false}
                  size="md"
                  showPointer={true}
                  onClick={() => {
                    MySwal.fire({
                      title: 'Are you sure you want to save your map changes?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Yes',
                      cancelButtonText: 'Cancel',
                    }).then((submit) => {
                      if (submit.isConfirmed) {
                        saveMap();
                      }
                    });
                  }}
                />
                <Icon
                  name={'add'}
                  title={'Cancel Edit'}
                  className="sonarIcon-rotate-45 align-sub nav-text-icon"
                  disabled={false}
                  variant="light"
                  size="md"
                  showPointer={true}
                  onClick={() => {
                    history.push('/mapbeta/' + mapId);
                    reset();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Card.Header>
    </div>
  );
}
