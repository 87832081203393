import './AssetScreen.scss';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { Prompt } from 'react-router';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  AddBeaconToAsset,
  ASSET_TYPE,
  BeaconEditAbilitiesPopUp,
  BeaconNameAssignedDateRenderer,
  CopyIconCellRenderer,
  DateTabs,
  DetailOptionsOverlay,
  EquipmentInfo,
  Icon,
  IconCellRenderer,
  Keywords,
  LineChart,
  Loading,
  ModalContainer,
  OnlineStatus,
  PrimaryBeaconRenderer,
  ScanIn,
  Text,
} from '../../components';
import AgGrid from '../../components/grids/AgGrid/AgGrid';
import { Export } from '../../components/utils';
import { toast } from '../../components/utils';
import { ABILITY_MAP, ONLINE_STATUS_MAP } from '../../constants';
import LocationMap from '../../modules/location/components/locationMap';
import { withDock } from '../../react';
import { Api } from '../../services';
import { getFormattedDateTime, isReadOnly, isUser } from '../../utils';
import AssetScreenDetails from './AssetScreenDetails';
import { assocBeaconsColumnDefs, scansColumnDefs } from './columnDefs';
import ValueDateCellRenderer from './ValueDateCellRenderer';

const MySwal = withReactContent(Swal);

const AssetScreen = ({
  pageType,
  asset,
  beacons,
  map,
  location,
  onChangeKeywords,
  keywords,
  identifiers,
  renderDock,
  getBeaconReadings,
  getBeaconReadingsExport,
  isFavorite,
  favoriteAsset,
  unfavoriteAsset,
  deleteAsset,
  onChangeIdentifiers,
  refetch,
  setAsset,
  scans,
  onAddScanIn,
}) => {
  // useState Hooks - #region INIT
  const [isAddingKeyword, setIsAddingKeyword] = useState(false);
  const [beaconReadingIndex, setBeaconReadingIndex] = useState(0);
  const [filteredBeaconsReadings, setFilterBeaconsReadings] = useState([]);
  const [loadingReadings, setLoadingReadings] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const [selectedBeacons, setSelectedBeacons] = useState([]);
  const [dataOption, setDataOption] = useState('mean');
  const [startDate, setStartDate] = useState(moment().subtract(12, 'h'));
  const [endDate, setEndDate] = useState(moment());
  const [columnDefs, setColumnDefs] = useState([]);
  const [editModeEnabled, setEditModeEnabled] = useState(true);
  const [disableMode, setDisableMode] = useState(false);
  const [assetNameDirty, setAssetNameDirty] = useState(false);
  const [passingValidation, setPassingValidation] = useState(false);
  const [assetName, setAssetName] = useState(asset.name);
  const [onAssetDetails, setOnAssetDetails] = useState(true);
  const BEACON_READING_OPTIONS = ['Mean', 'Max', 'Value', 'N', 'Min'];
  const [hideAddKeywords, setHideAddKeywords] = useState(false);
  const [isAdvancedMode, setIsAdvancedMode] = useState(pageType == 'advancedAsset');
  const [doubleCheck, setDoubleCheck] = useState(false);
  const [equipDirty, setEquipDirty] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [isAddingIdentifier, setIsAddingIdentifier] = useState(false);
  const [userTempPref, setUserTempPref] = useState(false);
  const [interSelectedRange, setInterSelectedRange] = useState(null);

  const assetTimestamp = getFormattedDateTime(asset.timestamp, '');
  // #endregion INIT

  //useHistory Hooks
  let history = useHistory();

  useEffect(() => {
    if (isReadOnly() === false && isUser() === false) return;
    setHideAddKeywords(true);
  }, []);

  // Needed to prevent columns flashing on click
  useEffect(() => {
    setColumnDefs(getColumnDefs());
  }, [beacons]);

  useEffect(() => {
    if (pageType === 'advancedAsset') return;

    setLoadingReadings(true);
    getBeaconReadings(selectedBeacons, startDate, endDate).then((data) => {
      setFilterBeaconsReadings(getFilteredBeaconReadings(data));
      setLoadingReadings(false);
    });
  }, [selectedBeacons]);

  //Show user warning message when changing or closing tab before saving asset name.
  useEffect(() => {
    //Allows user to reload page or close tab if asset name was saved.
    if (doubleCheck === false) return;

    const beforeUnloadListener = (event) => {
      event.preventDefault();
      return (event.returnValue = 'Are you sure you want to exit?');
    };

    window.addEventListener('beforeunload', beforeUnloadListener, { capture: true });

    return () =>
      window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
  }, [doubleCheck]);

  useEffect(() => {
    if (assetNameDirty === true || equipDirty === true) {
      setDoubleCheck(true);
    } else if (assetNameDirty === false || equipDirty === false) {
      setDoubleCheck(false);
    }
  }, [assetNameDirty, equipDirty]);

  const getExport = async (exportType, index, ability) => {
    const resp = await Api.get('/users');
    let updatedUserTempPref = userTempPref;
    if (resp.data.temperatureUnit === 'Fahrenheit' && ability === 'Temperature') {
      const result = await Swal.fire({
        title: 'Export Temperature Readings in Fahrenheit?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      updatedUserTempPref = result.isConfirmed;
      setUserTempPref(updatedUserTempPref);
    } else {
      updatedUserTempPref = false;
    }
    setCsvLoading(true);
    const params = {
      responseType: 'blob',
      headers: { Accept: exportType },
    };
    const res = await getBeaconReadingsExport(
      selectedBeacons,
      startDate,
      endDate,
      updatedUserTempPref,
      params,
    );
    setTimeout(() => {
      setCsvLoading(false);
    }, 1000);
    return res[index];
  };

  // New date range handle when interacting with associated beacon graphs
  const handleRangeUpdate = (newRange) => {
    setInterSelectedRange(newRange);
  };

  // When user interacts with range of linechart associated beacon graph will update date range displayed & data
  useEffect(() => {
    if (interSelectedRange) {
      setStartDate(moment(interSelectedRange[0]));
      setEndDate(moment(interSelectedRange[1]));
    }
  }, [interSelectedRange]);

  //Show user sweet alert message modal when user is about to navigate away from current page without saving asset name.
  const showMessage = (location, action) => {
    return MySwal.fire({
      allowOutsideClick: false,
      title: 'Are you sure?',
      text: 'Your changes will not be saved.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
    }).then((submit) => {
      if (submit.isConfirmed) {
        setDoubleCheck(false);

        history.push(location.pathname);
        MySwal.close();
      } else {
        MySwal.close();
      }

      return submit;
    });
  };

  // #region HELPER FUNCTIONS
  function getGridProps() {
    return {
      frameworkComponents: {
        BeaconEditAbilitiesPopUp,
        BeaconNameAssignedDateRenderer,
        PrimaryBeaconRenderer,
        ValueDateCellRenderer,
        IconCellRenderer: !isReadOnly() && IconCellRenderer,
        CopyIconCellRenderer,
      },
      rowHeight: 100,
    };
  }

  function openCreateBeaconFormDock() {
    renderDock({
      render: () => (
        <AddBeaconToAsset
          initAssetType={ASSET_TYPE.EXISTING}
          initAsset={asset}
          onAssetDetailsPage={onAssetDetails}
          existingBeacons={beacons}
          onSuccess={() => {
            refetch();
            toast.success('Successfully added associated beacon!');
          }}
        />
      ),
    });
  }

  const onChangeReadingsDate = useCallback(
    async (startDate, endDate) => {
      setStartDate(startDate);
      setEndDate(endDate);
      setLoadingReadings(true);
      const readings = await getBeaconReadings(selectedBeacons, startDate, endDate);
      setFilterBeaconsReadings(getFilteredBeaconReadings(readings));
      setLoadingReadings(false);
    },
    [selectedBeacons],
  );

  /**
   * @desc Filter beacon readings that match the beacon user selected with dropdown.
   */
  function getFilteredBeaconReadings(beaconReadings) {
    if (beaconReadings && beaconReadings.length > 0) {
      if (beaconReadingIndex === 0) return beaconReadings;

      return beaconReadings.filter(
        (currReading) => currReading.beacon.id === beacons[beaconReadingIndex - 1].id, // subtract 1 from first option being select all
      );
    }
    return [];
  }

  const renderReadingsCharts = () => {
    if (loadingReadings) return null;

    if (!filteredBeaconsReadings.length) return <h1>No readings.</h1>;

    return filteredBeaconsReadings.map((currReading, index) => {
      // ensures all readings are in chronological order
      const readings = currReading.readings.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
      let abilityFormatter = ABILITY_MAP[currReading.ability] || ABILITY_MAP['Generic'];
      return (
        <div key={index + 'container'} className="d-flex flex-column justify-content-end">
          <div key={index + 'iconWrapper'} className="d-flex flex-row justify-content-end">
            <Export
              filename={`${currReading.beacon.name} - ${currReading.ability}_Export`}
              options={['CSV', 'JSON']}
              csvLoading={csvLoading}
              getExport={async (option) => {
                return await getExport(option, index, currReading.ability);
              }}
            />
            <Icon
              name="add"
              key={index + 'close'}
              title={index + ''}
              size="md"
              disabled={false}
              className="sonarIcon-rotate-45 align-sub exit-icon"
              variant="light"
              showPointer={true}
              onClick={onClose}
            />
          </div>
          <LineChart
            key={index}
            header={`${currReading.beacon.name} - ${currReading.ability}`}
            ability={currReading.ability}
            data={readings.map((reading) => abilityFormatter.numValue(reading, dataOption))}
            labels={readings.map((reading) => new Date(reading.timestamp))}
            useYAxisBuffer={currReading.ability === ABILITY_MAP.Vibration.name}
            onRangeUpdate={handleRangeUpdate}
          />
        </div>
      );
    });
  };

  // Dynamically renders all relevant abilities in the Associated Beacons chart
  const getColumnDefs = () => {
    if (pageType === 'advancedAsset') return;
    if (beacons.length === 0) return;
    let defs = [];
    let types = [];
    defs = assocBeaconsColumnDefs.map((item) => ({ ...item }));
    beacons.forEach((beacon) =>
      beacon.readings.forEach((reading) => {
        if (!types.includes(reading.ability) && reading.ability !== 'Health Check') {
          types.push(reading.ability);
        }
      }),
    );
    types.forEach((value) => {
      const newAbility = {
        headerName: value,
        valueGetter(params) {
          const { readings } = params.data;
          const reading = readings.find((currReading) => currReading.ability === value);
          return reading || 'N/A';
        },
        cellRenderer: ValueDateCellRenderer,
        cellClassRules: {
          'cell-button': 'x !== "N/A"',
        },
      };
      const index = defs.findIndex((object) => object.headerName === value);
      if (index === -1) {
        defs.push(newAbility);
      }
    });
    defs.push({
      headerName: '',
      minWidth: 1,
      flex: 2,
    });

    return defs;
  };

  // Change Asset name input logic

  const onlySpaces = (str) => {
    return str.trim().length === 0;
  };

  useEffect(() => {
    const errorMsg = document.getElementById('errorMsg');
    if (assetName === '' || onlySpaces(assetName) === true) {
      setPassingValidation(false);
      setEditModeEnabled(editModeEnabled);
    } else {
      errorMsg.hidden = true;
      setPassingValidation(true);
    }
  }, [assetName]);

  const handleAssetNameChange = (e) => {
    setAssetName(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      onSaveAssetName();
    }
  };

  const onSaveAssetName = () => {
    const errorMsg = document.getElementById('errorMsg');
    setEditModeEnabled(true);
    if (assetName === '' || onlySpaces(assetName) === true) {
      errorMsg.hidden = false;
      setEditModeEnabled(editModeEnabled);
    } else {
      errorMsg.hidden = true;
      asset.name = assetName;
      if (!assetName) return;
      setAsset({ ...asset, assetName });
      setAssetNameDirty(false);
    }
  };

  // Render charts on click
  const onCellClick = (e) => {
    const beacon = beacons.filter((beacon) => {
      return beacon.id === e.data.id;
    });

    if (e.colDef.headerName !== '') {
      const newSelection =
        e.colDef.headerName === 'Device ID'
          ? { beacon: beacon[0], abilityId: 21, ability: 'Health Check' }
          : { beacon: beacon[0], abilityId: e.value.abilityId, ability: e.value.ability };

      const index = selectedBeacons.findIndex(
        (item) =>
          item.abilityId === newSelection.abilityId && item.beacon.id === newSelection.beacon.id,
      );

      if (index === -1) {
        const attributeExists = newSelection.beacon.readings.filter(
          (reading) => reading.abilityId === newSelection.abilityId,
        );
        if (attributeExists.length > 0) {
          setSelectedBeacons((selectedBeacons) => [...selectedBeacons, newSelection]);
        }
      }
    }
  };

  // Removes chart
  const onClose = (e) => {
    let index = parseInt(e.currentTarget.title);
    setSelectedBeacons(selectedBeacons.filter((item, i) => i !== index));
  };

  // Select chart data type
  const onChangeDataOption = (val) => {
    switch (val) {
      case 0:
        setDataOption('mean');
        break;
      case 1:
        setDataOption('max');
        break;
      case 2:
        setDataOption('value');
        break;
      case 3:
        setDataOption('n');
        break;
      case 4:
        setDataOption('min');
        break;
      default:
        setDataOption('mean');
        break;
    }
  };

  const openScanInsUI = async () => {
    await MySwal.fire({
      allowOutsideClick: false,
      showConfirmButton: false,
      html: (
        <ModalContainer close={() => MySwal.close()}>
          <ScanIn onAddScanIn={onAddScanIn} />
        </ModalContainer>
      ),
    });
  };

  const renderTooltip = (tip) => <Tooltip id="button-tooltip">{tip}</Tooltip>;
  // #endregion HELPER FUNCTIONS

  const toggleMode = () => {
    setIsAdvancedMode(!isAdvancedMode);
    const newPath = isAdvancedMode ? `/assets/${asset.id}` : `/assets/${asset.id}/advanced`;
    setTimeout(() => {
      history.push(newPath);
    }, 200); // Delay of 2 seconds (2000 milliseconds)
  };

  return (
    <>
      {
        /* Asset Header for Advanced asset page */
        pageType === 'advancedAsset' ? (
          <Card.Title className="d-flex flex-row justify-content-between align-items-center mt-1 mb-1">
            <div className="advanced-asset-asset-header d-flex flex-row align-items-center w-100">
              <OnlineStatus variant={ONLINE_STATUS_MAP[asset.status].variant} className="me-3" />
              <form className="asset-name-form">
                <Text id="errorMsg" as="p" hidden>
                  * Please enter a valid Asset Name.
                </Text>
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{assetName}</Tooltip>}>
                  <span className="d-inline-block">
                    <input
                      id="assetName"
                      type="text"
                      name="asset-name"
                      value={assetName}
                      onKeyDown={handleKeyDown}
                      disabled={editModeEnabled}
                      onChange={handleAssetNameChange}
                    />
                  </span>
                </OverlayTrigger>
                {assetNameDirty === true && (
                  <Icon
                    className={`align-sub ${
                      passingValidation ? 'in-edit-mode' : 'not-in-edit-mode'
                    }`}
                    variant="light"
                    name="check"
                    size="lg"
                    disabled={disableMode}
                    onClick={onSaveAssetName}
                  />
                )}
              </form>
              <div className="d-flex flex-row justify-content-end">
                <Form className="d-flex pt-1 pe-3 ps-3">
                  <Form.Check
                    type="switch"
                    className="asset-toggle-switch"
                    id={`assetToggleSwitch-${asset.id}`}
                    checked={isAdvancedMode}
                    onChange={toggleMode}
                  />
                </Form>

                <Icon
                  name={isFavorite ? 'star-fill' : 'star-empty'}
                  variant="light"
                  size="lg"
                  className="fw-bold align-sub favorite-star"
                  disabled={false}
                  showPointer={true}
                  onClick={isFavorite ? unfavoriteAsset : favoriteAsset}
                />
                {!isReadOnly() && (
                  <DetailOptionsOverlay
                    setShowIcon={setShowIcon}
                    setAssetNameDirty={setAssetNameDirty}
                    showEditBtn={true}
                    setEditModeEnabled={setEditModeEnabled}
                    onClickDelete={deleteAsset}
                  />
                )}
              </div>
            </div>
          </Card.Title>
        ) : (
          <>
            <Card className="border-0 shadow-none asset-details-asset-card">
              {/* Asset Details page */}
              <Row>
                <Col xs={12} xxl={map ? 6 : 12}>
                  <Card className="mb-5">
                    <Card.Header>
                      <Card.Title className="d-flex flex-row justify-content-between align-items-center mt-1 mb-1">
                        <div className="d-flex flex-row align-items-center w-100">
                          <OnlineStatus
                            variant={ONLINE_STATUS_MAP[asset.status].variant}
                            className="me-3"
                          />
                          <form className="asset-name-form">
                            <Text id="errorMsg" as="p" hidden>
                              * Please enter a valid Asset Name.
                            </Text>
                            <OverlayTrigger
                              overlay={<Tooltip id="tooltip-disabled">{assetName}</Tooltip>}
                            >
                              <span className="d-inline-block">
                                <input
                                  id="assetName"
                                  type="text"
                                  name="asset-name"
                                  value={assetName}
                                  onKeyDown={handleKeyDown}
                                  disabled={editModeEnabled}
                                  onChange={handleAssetNameChange}
                                />
                              </span>
                            </OverlayTrigger>
                            {assetNameDirty === true && (
                              <Icon
                                className={`align-sub ${
                                  passingValidation ? 'in-edit-mode' : 'not-in-edit-mode'
                                }`}
                                variant="light"
                                name="check"
                                size="lg"
                                disabled={disableMode}
                                showPointer={true}
                                onClick={onSaveAssetName}
                              />
                            )}
                          </form>
                        </div>

                        {!assetTimestamp.includes('Invalid') && <Text>{assetTimestamp}</Text>}
                        {/* Checks if Asset Type is a HUMS asset, Hums (Show Toggle), Basic (Hide Toggle) */}
                        {asset.assetType === 'Hums' && (
                          <Form className="pe-3 ps-3">
                            <Form.Check
                              type="switch"
                              className="asset-toggle-switch"
                              id={`assetToggleSwitch-${asset.id}`}
                              checked={isAdvancedMode}
                              onChange={toggleMode}
                            />
                          </Form>
                        )}
                        <div className="d-flex flex-row justify-content-end">
                          <Icon
                            name={isFavorite ? 'star-fill' : 'star-empty'}
                            variant="light"
                            size="lg"
                            className="fw-bold align-sub favorite-star"
                            disabled={false}
                            showPointer={true}
                            onClick={isFavorite ? unfavoriteAsset : favoriteAsset}
                          />
                          {isReadOnly() === false && (
                            <DetailOptionsOverlay
                              setAssetNameDirty={setAssetNameDirty}
                              showEditBtn={true}
                              setEditModeEnabled={setEditModeEnabled}
                              onClickDelete={deleteAsset}
                            />
                          )}
                        </div>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <AssetScreenDetails
                        assetId={asset.id.toString()}
                        mapName={asset.mapName}
                        placeName={asset.placeName}
                      />
                    </Card.Body>
                  </Card>

                  {/* Keywords */}
                  <Card className="mb-5 overflow-auto">
                    <Card.Header>
                      <Card.Title className="d-flex flex-row justify-content-between align-items-center mt-1 mb-1">
                        <Text>Keywords</Text>
                        {!isReadOnly() && (
                          <Icon
                            name="add"
                            variant="light"
                            size="md"
                            className={'sonarIcon-circle add'}
                            disabled={isReadOnly()}
                            showPointer={true}
                            onClick={() => {
                              setIsAddingKeyword(!isAddingKeyword);
                            }}
                          />
                        )}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body className="p-5">
                      <Keywords
                        initKeywords={keywords}
                        onChange={onChangeKeywords}
                        isAddingKeyword={isAddingKeyword}
                        EmptyComp={() =>
                          hideAddKeywords === false ? (
                            <Text>
                              Add a Keyword
                              <Icon
                                name="add"
                                variant="light"
                                size="xs"
                                className="sonarIcon-circle"
                                disabled={false}
                                showPointer={true}
                                onClick={() => setIsAddingKeyword(!isAddingKeyword)}
                              />
                            </Text>
                          ) : null
                        }
                      />
                    </Card.Body>
                  </Card>

                  {/* Key Value Pairs */}
                  <Card className="mb-5 overflow-auto">
                    <Card.Header>
                      <Card.Title className="d-flex flex-row justify-content-between align-items-center mt-1 mb-1">
                        <Text>Key-Value Pairs</Text>
                        {!isReadOnly() && (
                          <Icon
                            name="add"
                            size="md"
                            variant="light"
                            disabled={isReadOnly()}
                            className={'sonarIcon-circle add'}
                            showPointer={true}
                            onClick={() => {
                              setIsAddingIdentifier(true);
                            }}
                          />
                        )}
                      </Card.Title>
                    </Card.Header>

                    <Card.Body className="p-3">
                      <EquipmentInfo
                        setIsAddingIdentifier={setIsAddingIdentifier}
                        isAddingIdentifier={isAddingIdentifier}
                        initData={identifiers}
                        updateIdentifiers={onChangeIdentifiers}
                        setEquipDirty={setEquipDirty}
                      />
                    </Card.Body>
                  </Card>
                </Col>

                {/* Map */}
                {map && (
                  <Col className="mb-5" xs={12} xxl={6}>
                    <Card className="asset-details-last-location">
                      <Card.Header>
                        <Card.Title className="d-flex flex-row justify-content-between align-items-center mt-1 mb-1">
                          <Text>Last Location</Text>
                          <Link to={`/assets/${asset.id}/maps`}>
                            <Icon
                              name="history"
                              className="sonarIcon-circle align-sub history"
                              variant="light"
                              size="md"
                              showPointer={true}
                              disabled={false}
                            />
                          </Link>
                        </Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <div className="last-location-map-div">
                          <LocationMap map={map} marker={location} height="100%" />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
              </Row>

              {/* Assoc Beacons */}
              <Row className="mb-5">
                <Col>
                  <Card className="overflow-auto associate-beacons">
                    <Card.Header>
                      <Card.Title className="d-flex flex-row justify-content-between align-items-center mt-1 mb-1">
                        <Text>
                          Associated Beacons
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip(
                              "Clicking on a field will display that field's associated graph.",
                            )}
                          >
                            <span className="d-inline-block">
                              <div className="info-asso-beacons" variant="link">
                                <Icon name="info" variant="dark" size="md" disabled={false} />
                              </div>
                            </span>
                          </OverlayTrigger>
                        </Text>
                        {!isReadOnly() && (
                          <Icon
                            name="add"
                            variant="light"
                            size="md"
                            className="sonarIcon-circle add"
                            disabled={isReadOnly()}
                            showPointer={true}
                            onClick={openCreateBeaconFormDock}
                          />
                        )}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <div className="mb-3 h-100">
                        <AgGrid
                          id={'Associated_Beacons'}
                          rowData={beacons}
                          columnDefs={columnDefs}
                          columnSize={'notruncate'}
                          renderHeader={() => null}
                          gridProps={getGridProps()}
                          onCellClick={onCellClick}
                          hasSearch={false}
                        />
                        {filteredBeaconsReadings.length > 0 && (
                          <div className="m-3">
                            <div className="d-flex flex-row mb-5 align-items-center justify-content-between">
                              <div className="d-flex flex-row align-items-center">
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip(
                                    'Selecting a data option changes the values for all visible graphs. All Health Check graphs are locked to N values.',
                                  )}
                                >
                                  <span className="d-inline-block">
                                    <div className="info-asso-beacons" variant="link">
                                      <Icon
                                        name="info"
                                        variant="light"
                                        size="md"
                                        disabled={false}
                                        showPointer={false}
                                      />
                                    </div>
                                  </span>
                                </OverlayTrigger>
                                <DropdownButton
                                  className="me-3"
                                  variant="secondary"
                                  title="Data Options"
                                  id="Data Options"
                                >
                                  {BEACON_READING_OPTIONS.map((option, index) => (
                                    <Dropdown.Item
                                      as="button"
                                      key={index}
                                      active={option.toLowerCase() === dataOption}
                                      onClick={() => {
                                        onChangeDataOption(index);
                                      }}
                                    >
                                      {option}
                                    </Dropdown.Item>
                                  ))}
                                </DropdownButton>
                              </div>
                              <DateTabs
                                onChangeDate={onChangeReadingsDate}
                                disableCalendar={false}
                              />
                            </div>
                            {loadingReadings && <Loading />}
                            <div className="chart-container">{renderReadingsCharts()}</div>
                          </div>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              {/* Scans */}
              <Row>
                <Col className="">
                  <Card className="mb-4 overflow-auto">
                    <Card.Header>
                      <Card.Title className="d-flex flex-row justify-content-between align-items-center mt-1 mb-1">
                        <Text>Scan Ins</Text>
                        {!isReadOnly() && (
                          <Icon
                            name="scan"
                            variant="light"
                            size="md"
                            className="sonarIcon-circle scan"
                            disabled={isReadOnly()}
                            onClick={openScanInsUI}
                            showPointer={true}
                          />
                        )}
                      </Card.Title>
                    </Card.Header>

                    <Card.Body>
                      <div className="h-100">
                        <AgGrid
                          id="AgGrid-Scans"
                          rowData={scans}
                          columnDefs={scansColumnDefs}
                          columnSize={'truncate'}
                          hasSearch={false}
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card>

            <Prompt
              when={doubleCheck}
              message={(location, action) => {
                if (action === 'POP') {
                  console.log('Backing up...');
                }
                showMessage(location, action);
                return false;
              }}
            />
          </>
        )
      }
    </>
  );
};

AssetScreen.propTypes = {
  pageType: PropTypes.string.isRequired,
  asset: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    location: PropTypes.any,
    timestamp: PropTypes.string,
  }).isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string.isRequired),
  beacons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      deviceId: PropTypes.string.isRequired,
      readings: PropTypes.arrayOf(
        PropTypes.shape({
          mean: PropTypes.number.isRequired,
          value: PropTypes.string.isRequired,
          timestamp: PropTypes.string.isRequired,
          ability: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  triggers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ),
  isFavorite: PropTypes.bool.isRequired,
  favoriteAsset: PropTypes.func.isRequired,
  unfavoriteAsset: PropTypes.func.isRequired,
  deleteAsset: PropTypes.func.isRequired,
  refetch: PropTypes.func,
  setAsset: PropTypes.func.isRequired,
  updateAsset: PropTypes.func.isRequired,
};

export default withDock(AssetScreen);
